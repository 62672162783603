import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Llm_user_mastery_home from './llm_user_mastery/Home';  // or whatever you named your home component
import Oneptwo from './llm_user_mastery/Oneptwo';
import Onepone from './llm_user_mastery/Onepone';
import Onepthree from './llm_user_mastery/Onepthree'
import Onepfour from './llm_user_mastery/Onepfour'
import Onepfive from './llm_user_mastery/Onepfive'
import Onepsix from './llm_user_mastery/Onepsix'
import Home from './Home'

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/llm_user_mastery" element={<Llm_user_mastery_home />} />
      <Route path="/llm_user_mastery_1_2_introduction_to_llms" element={<Oneptwo />} />
      <Route path="/llm_user_mastery_1_1_welcome_and_introduction" element={<Onepone />} />
      <Route path="/llm_user_mastery_1_3_accessing_llms" element={<Onepthree />} />
      <Route path="/llm_user_mastery_1_4_llm_security_and_responsible_use" element={<Onepfour />} />
      <Route path="/llm_user_mastery_1_5_prompting" element={<Onepfive />} />
      <Route path="/llm_user_mastery_1_6_workshop" element={<Onepsix />} />
      {/* Add other routes as needed */}
    </Routes>
  );
};

export default App;