import React, { useState } from 'react';
import './FlipCard.css';

const FlipCard = ({ frontTitle, frontIcon, frontContent, backTitle, backContent }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div 
      className="flip-card h-[400px] perspective-1000" 
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className={`relative w-full h-full transition-transform duration-500 transform-style-3d cursor-pointer ${isFlipped ? 'rotate-y-180' : ''}`}>
        {/* Front of card */}
        <div className="absolute w-full h-full backface-hidden">
          <div className="h-full bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30 hover:border-blue-500/50 transition-all">
            <h4 className="text-xl font-semibold text-blue-400 mb-3 flex items-center">
              <span className="bg-blue-500/10 rounded-xl p-2 mr-3">
                {frontIcon}
              </span>
              {frontTitle}
            </h4>
            {frontContent}
          </div>
        </div>

        {/* Back of card */}
        <div className="absolute w-full h-full backface-hidden rotate-y-180">
          <div className="h-full bg-slate-800/40 backdrop-blur-xl rounded-xl p-6 border border-blue-500/30 hover:border-blue-500/50 transition-all">
            <h4 className="text-xl font-semibold text-blue-400 mb-3">
              {backTitle}
            </h4>
            <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500/50 scrollbar-track-slate-700/30 h-[calc(100%-4rem)] pr-2">
              {backContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;