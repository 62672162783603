import React, { useState, useEffect } from 'react';
import { CheckCircle2, XCircle, ArrowRight, RefreshCw } from 'lucide-react';

const Quiz = ({ questions, onQuizComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [showExplanation, setShowExplanation] = useState(false);

  const handleAnswerClick = (answerIndex) => {
    if (userAnswers[currentQuestion] !== undefined) return;

    const newUserAnswers = { ...userAnswers, [currentQuestion]: answerIndex };
    setUserAnswers(newUserAnswers);
    setShowExplanation(true);

    if (answerIndex === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    if (currentQuestion === questions.length - 1) {
      setShowScore(true);
    }
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    setShowExplanation(false);
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    }
  };

  const resetQuiz = () => {
    setCurrentQuestion(0);
    setShowScore(false);
    setScore(0);
    setUserAnswers({});
    setShowExplanation(false);
  };

  useEffect(() => {
    if (showScore) {
      const percentage = Math.round((score / questions.length) * 100);
      if (percentage >= 75 && onQuizComplete) {
        onQuizComplete();
      }
    }
  }, [showScore, score, questions.length, onQuizComplete]);

  if (!questions || questions.length === 0) {
    return <div>No questions available.</div>;
  }

  if (showScore) {
    const percentage = Math.round((score / questions.length) * 100);
    const isPassing = percentage >= 75;

    return (
      <div className="bg-gray-800/30 rounded-lg p-6 border border-gray-700">
        <div className={`p-4 mb-6 rounded-lg border ${
          isPassing 
            ? 'bg-green-500/10 border-green-500/50' 
            : 'bg-red-500/10 border-red-500/50'
        }`}>
          <h3 className="text-xl font-semibold text-white mb-2">Quiz Complete!</h3>
          <p className="text-lg text-gray-300 mb-2">
            You scored {score} out of {questions.length} ({percentage}%)
          </p>
          {isPassing ? (
            <p className="text-green-400">
              Excellent work! You've demonstrated a strong understanding of the material.
            </p>
          ) : (
            <p className="text-red-400">
              We recommend reviewing the material and trying again to strengthen your understanding.
            </p>
          )}
        </div>

        <div className="space-y-6 mb-6">
          {questions.map((question, index) => (
            <div key={index} className="bg-gray-800/50 rounded-lg p-6">
              <p className="text-white mb-4">{question.questionText}</p>
              
              <div className="flex items-center space-x-2 text-sm mb-4">
                {userAnswers[index] === question.correctAnswer ? (
                  <div className="flex items-center text-green-400">
                    <CheckCircle2 className="mr-2" size={16} />
                    <span>Correct! You selected: {question.answerOptions[userAnswers[index]]}</span>
                  </div>
                ) : (
                  <div className="space-y-2">
                    <div className="flex items-center text-red-400">
                      <XCircle className="mr-2" size={16} />
                      <span>You selected: {question.answerOptions[userAnswers[index]]}</span>
                    </div>
                    <div className="flex items-center text-green-400">
                      <CheckCircle2 className="mr-2" size={16} />
                      <span>Correct answer: {question.answerOptions[question.correctAnswer]}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="bg-gray-900/50 rounded-lg p-4 text-sm">
                <p className="text-gray-300">
                  {userAnswers[index] === question.correctAnswer 
                    ? question.explanations.correct
                    : question.explanations.incorrect[userAnswers[index]]
                  }
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex space-x-4">
          <button
            onClick={resetQuiz}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded-lg text-white font-medium transition-colors"
          >
            <RefreshCw size={16} />
            <span>Try Again</span>
          </button>
          {!isPassing && (
            <button
              onClick={() => window.scrollTo(0, 0)}
              className="px-4 py-2 bg-gray-600 hover:bg-gray-500 rounded-lg text-white font-medium transition-colors"
            >
              Review Material
            </button>
          )}
        </div>
      </div>
    );
  }

  const question = questions[currentQuestion];

  return (
    <div className="bg-gray-800/30 rounded-lg p-6 border border-gray-700">
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <p className="text-sm text-gray-400">
            Question {currentQuestion + 1} of {questions.length}
          </p>
          <p className="text-sm text-gray-400">
            Score: {score}/{currentQuestion + (userAnswers[currentQuestion] !== undefined ? 1 : 0)}
          </p>
        </div>
        <h3 className="text-xl text-white">{question.questionText}</h3>
      </div>

      <div className="space-y-4">
        {question.answerOptions.map((answer, index) => (
          <div key={index} className="space-y-2">
            <button
              onClick={() => handleAnswerClick(index)}
              disabled={userAnswers[currentQuestion] !== undefined}
              className={`w-full p-4 rounded-lg text-left transition-all ${
                userAnswers[currentQuestion] === undefined
                  ? 'bg-gray-700/50 hover:bg-gray-700 border border-gray-600 hover:border-blue-500'
                  : userAnswers[currentQuestion] === index
                  ? index === question.correctAnswer
                    ? 'bg-green-500/20 border border-green-500'
                    : 'bg-red-500/20 border border-red-500'
                  : 'bg-gray-700/50 border border-gray-600 opacity-50'
              }`}
            >
              {answer}
            </button>

            {showExplanation && userAnswers[currentQuestion] === index && (
              <div className={`p-4 rounded-lg ${
                index === question.correctAnswer 
                  ? 'bg-green-500/10 border border-green-500/50' 
                  : 'bg-red-500/10 border border-red-500/50'
              }`}>
                <p className="text-sm text-gray-300">
                  {index === question.correctAnswer 
                    ? question.explanations.correct
                    : question.explanations.incorrect[index]
                  }
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      {userAnswers[currentQuestion] !== undefined && currentQuestion < questions.length - 1 && (
        <div className="mt-6">
          <button
            onClick={handleNextQuestion}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded-lg text-white font-medium transition-colors"
          >
            <span>Next Question</span>
            <ArrowRight size={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Quiz;