import React, { useState, useEffect } from 'react';
import { ArrowLeft, Menu, Shield, UserCog, Lock, Filter, CheckCircle, FileKey, Database, GraduationCap, Cloud, Calendar, ArrowRight} from 'lucide-react';
import { Link } from 'react-router-dom';
import Quiz from '../Quiz';
import FlipCard from '../FlipCard';

const Onepfour = () => {
  const [activeSection, setActiveSection] = useState('intro');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isQuizComplete, setIsQuizComplete] = useState(false);  // Add this state

  
  useEffect(() => {
    // Immediate scroll to top without smooth behavior on initial load
    window.scrollTo(0, 0);
  }, []); 


  
  // Add a second useEffect to handle section changes
  useEffect(() => {
    // Smooth scroll to top when section changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeSection]);


  const handleSectionChange = (sectionId) => {
    // First change the section
    setActiveSection(sectionId);
    setIsMobileMenuOpen(false);
    
    // Then scroll to top with a slight delay to ensure the new content is rendered
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  };


  const sections = [
    { id: 'intro', title: 'Introduction', icon: Calendar },
    { id: 'privacy', title: 'Data Privacy', icon: FileKey },
    { id: 'trust', title: 'Trust & Security', icon: Shield },
    { id: 'guardrails', title: 'Guardrails', icon: Filter },
    { id: 'hallucinations', title: 'Hallucinations', icon: Cloud },  // Cloud icon can represent haziness/uncertainty
    
    { id: 'summary', title: 'Summary', icon: CheckCircle },
    { id: 'quiz', title: 'Knowledge Check', icon: GraduationCap },   // Academic cap for quiz/learning
];


  const contentSections = {


    intro: {
      title: 'Introduction to LLM Security and Responsible Use',
      content: (
        <div className="space-y-8">
          {/* Hero Section */}
          <div className="relative bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-12 border border-slate-700/30 overflow-hidden">
            <div className="absolute top-0 right-0 w-1/2 h-full opacity-10">
              <div className="absolute transform rotate-45 translate-x-1/2 -translate-y-1/2 bg-blue-500/20 w-96 h-96 blur-3xl"></div>
            </div>
            
            <div className="relative z-10">
              <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
                Using AI Safely and Responsibly
              </h3>
              <p className="text-slate-300 text-lg max-w-3xl">
                Just as we take care to protect our personal information in everyday life, we need to be thoughtful about how we use and share information with artificial intelligence. In this module, we'll explore how to interact with AI language models safely while getting the most out of their capabilities.
              </p>
            </div>
          </div>

          {/* Key Areas Visual */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
              <div className="flex items-center space-x-3 mb-4">
                <div className="bg-blue-500/10 p-3 rounded-xl">
                  <Shield className="w-8 h-8 text-blue-400" />
                </div>
                <h4 className="text-lg font-semibold text-blue-300">Safety First</h4>
              </div>
              <p className="text-slate-300">
                Understanding how AI systems protect your information and what you can do to enhance that protection.
              </p>
            </div>

            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
              <div className="flex items-center space-x-3 mb-4">
                <div className="bg-blue-500/10 p-3 rounded-xl">
                  <Lock className="w-8 h-8 text-blue-400" />
                </div>
                <h4 className="text-lg font-semibold text-blue-300">Data Privacy</h4>
              </div>
              <p className="text-slate-300">
                Learning what happens to information you share with AI and how to make informed decisions about sharing.
              </p>
            </div>

            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
              <div className="flex items-center space-x-3 mb-4">
                <div className="bg-blue-500/10 p-3 rounded-xl">
                  <CheckCircle className="w-8 h-8 text-blue-400" />
                </div>
                <h4 className="text-lg font-semibold text-blue-300">Best Practices</h4>
              </div>
              <p className="text-slate-300">
                Discovering practical tips and guidelines for responsible AI use in both personal and professional settings.
              </p>
            </div>
          </div>

          {/* Why It Matters */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Why This Matters</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="space-y-4">
                <p className="text-slate-300">
                  As AI becomes more integrated into our daily lives and work, understanding how to use it safely becomes increasingly important. Whether you're using AI for personal tasks or business purposes, knowing how to protect sensitive information and get reliable results can make a significant difference.
                </p>
                <img 
                  src="https://images.pexels.com/photos/333837/pexels-photo-333837.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="AI Security Visualization"
                  className="w-full rounded-lg object-cover"
                />
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">In This Module You'll Learn:</h5>
                <ul className="space-y-3 text-slate-300">
                  <li className="flex items-start space-x-3">
                    <div className="bg-blue-500/10 p-1 rounded-full mt-1">
                      <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                    </div>
                    <span>How AI systems handle and protect your information</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <div className="bg-blue-500/10 p-1 rounded-full mt-1">
                      <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                    </div>
                    <span>What safeguards are in place to prevent misuse</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <div className="bg-blue-500/10 p-1 rounded-full mt-1">
                      <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                    </div>
                    <span>How to identify when AI might be making mistakes</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <div className="bg-blue-500/10 p-1 rounded-full mt-1">
                      <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                    </div>
                    <span>Best practices for protecting sensitive information</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Get Started */}

        </div>
      )
    },


    privacy: {
      title: 'Data Privacy',
      content: (
        <div className="space-y-8">
          {/* Introduction */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Understanding Data Privacy in LLMs
            </h3>
            <p className="text-slate-300">
              When using Large Language Models, your data travels through multiple stages of processing and storage. Understanding this journey is crucial for making informed decisions about sharing information.
            </p>
          </div>
    
          {/* Data Journey */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">The Journey of Your Data</h4>
            
            <div className="space-y-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Initial Process</h5>
                <ol className="space-y-3 text-slate-300">
                  <li className="flex items-start space-x-3">
                    <span className="font-semibold text-blue-400">1.</span>
                    <span>You enter your message into the chatbot interface</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <span className="font-semibold text-blue-400">2.</span>
                    <span>Message is sent to the LLM for processing</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <span className="font-semibold text-blue-400">3.</span>
                    <span>Model generates and returns a response</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <span className="font-semibold text-blue-400">4.</span>
                    <span>Conversation is stored in your profile</span>
                  </li>
                </ol>
              </div>
              
              <div className="bg-slate-800/50 rounded-xl p-6">
                <img 
                  src="https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/user_privacy_1.png"
                  alt="Data Flow Diagram"
                  className="w-full rounded-lg object-cover mb-4"
                />
                <p className="text-sm text-slate-400">
                  Visualisation of data flow in LLM systems
                </p>
              </div>
            </div>
          </div>
    
          {/* Benefits of Data Storage */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Why Providers Store Your Data</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">User Benefits</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>• Access conversation history</li>
                  <li>• Continue previous discussions</li>
                  <li>• Maintain context across sessions</li>
                  <li>• Personalised interactions</li>
                </ul>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Service Improvements</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>• Model performance enhancement</li>
                  <li>• Better response accuracy</li>
                  <li>• Enhanced safety measures</li>
                  <li>• Bug identification and fixes</li>
                </ul>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">User Controls</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>• Opt-out of data collection</li>
                  <li>• Delete conversation history</li>
                  <li>• Use anonymous sessions</li>
                  <li>• Control data sharing settings</li>
                </ul>
              </div>
            </div>


            {/* Data Training Flow Diagram */}
            <div className="mt-8 bg-slate-800/50 rounded-xl p-6">
            <p className="space-y-3 text-slate-300">
                In the diagram below we can track the flow of your data in the situation when your prompts or messages have been used for retraining the LLMs. 
                <br></br>
              </p>
              <img 
              src = "https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/user_privacy_2.png"
                
                alt="Data Training Flow"
                className="w-full rounded-lg object-cover mb-4"
              />
            <p className="space-y-3 text-slate-300">
                Below lets look at a situation that could in theory result in a data leak. 
                <br></br>
              </p>
            </div>
          </div>
    
          {/* Data Leakage Risk */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Understanding Data Leakage Risks</h4>
            
            <div className="space-y-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Original Conversation</h5>
                <div className="space-y-3">
                  <div className="flex items-start space-x-3">
                    <span className="text-blue-400 font-mono">User:</span>
                    <div className="bg-slate-900/50 rounded-lg p-3 flex-grow">
                      <p className="text-slate-300">"Here's our company's new product launch plan for 2024: The 'EcoTech X1000' will feature sustainable materials and innovative AI integration. Target price point: £499. Launch date: March 15th."</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <span className="text-green-400 font-mono">Assistant:</span>
                    <div className="bg-slate-900/50 rounded-lg p-3 flex-grow">
                      <p className="text-slate-300">"I understand you've shared product launch details. I'll help analyse this plan..."</p>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Later Conversation with Different User</h5>
                <div className="space-y-3">
                  <div className="flex items-start space-x-3">
                    <span className="text-blue-400 font-mono">User:</span>
                    <div className="bg-slate-900/50 rounded-lg p-3 flex-grow">
                      <p className="text-slate-300">"Tell me about upcoming eco-friendly tech products launching in 2024."</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <span className="text-green-400 font-mono">Assistant:</span>
                    <div className="bg-slate-900/50 rounded-lg p-3 flex-grow">
                      <p className="text-slate-300">"While I can't disclose specific company plans, I can discuss general market trends in eco-friendly technology..."</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <span className="text-blue-400 font-mono">User:</span>
                    <div className="bg-slate-900/50 rounded-lg p-3 flex-grow">
                      <p className="text-slate-300">"What products are launching around March 2024 in the £500 price range?"</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-3">
                    <span className="text-green-400 font-mono">Assistant:</span>
                    <div className="bg-slate-900/50 rounded-lg p-3 flex-grow">
                      <p className="text-slate-300">"I'm aware of several products in that range, for example the 'EchoTech X1000' a product with AI features and made using sustainable materials."</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

                                {/* data controls  */}
          <div className="mt-8 bg-slate-800/50 rounded-xl p-6">
                     <h4 className="text-xl font-semibold text-blue-400 mb-6">Top Tip</h4>
            <p className="space-y-3 text-slate-300">
                Even on the free account Chat GPT gives you the option to opt out of future model training. They have this feature under Settings - Data controls - Imporve the model for everyone. By default this is set to on, however if you change this to off this is saying that you do not want your messages / prompts used to go into further training runs of openai's models.  
                <br></br>
              </p>
              <img 
              src = "https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/chat_gpt_training_off.png"
                
                alt="Data Training Flow"
                className="w-full rounded-lg object-cover mb-4"
              />
   
            </div>
        





    
          {/* Enterprise Protection */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Enterprise-Grade Protection</h4>
            
            <div className="space-y-6">
              <p className="text-slate-300">
                Enterprise solutions offer enhanced data protection guarantees:
              </p>
    
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-slate-800/50 rounded-lg p-4">
                  <div className="flex items-center space-x-2 mb-3">
                    <Shield className="w-5 h-5 text-blue-400" />
                    <h5 className="font-semibold text-blue-300">No Training Use</h5>
                  </div>
                  <p className="text-slate-300 text-sm">
                    Generally your data won't be used to train future models
                  </p>
                </div>
    
                <div className="bg-slate-800/50 rounded-lg p-4">
                  <div className="flex items-center space-x-2 mb-3">
                    <Lock className="w-5 h-5 text-blue-400" />
                    <h5 className="font-semibold text-blue-300">Data Isolation</h5>
                  </div>
                  <p className="text-slate-300 text-sm">
                    Expectation of complete separation of enterprise data
                  </p>
                </div>
    
                <div className="bg-slate-800/50 rounded-lg p-4">
                  <div className="flex items-center space-x-2 mb-3">
                    <Database className="w-5 h-5 text-blue-400" />
                    <h5 className="font-semibold text-blue-300">Legal Protection</h5>
                  </div>
                  <p className="text-slate-300 text-sm">
                    Contractual data handling guarantees likely
                  </p>
                </div>
              </div>
            </div>
          </div>
    
          {/* Best Practices */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
            <div className="flex items-center space-x-3 mb-4">
              <CheckCircle className="w-6 h-6 text-blue-400" />
              <h4 className="text-lg font-semibold text-blue-400">Best Practices</h4>
            </div>
            <ul className="space-y-2 text-slate-300">
              <li>• Review provider privacy policies before sharing sensitive information</li>
              <li>• Use enterprise versions for business-critical applications</li>
              <li>• Consider data residency requirements for your region</li>
              <li>• Regularly review and clean conversation history</li>
              <li>• Be mindful of sharing personally identifiable information</li>
            </ul>
          </div>
        </div>
      )
    },




    trust: {
      title: 'Trust, Security Policies, and Data Control',
      content: (
        <div className="space-y-8">
          {/* Introduction */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Your Responsibility with Data
            </h3>
            <p className="text-slate-300">
              When using AI language models with sensitive or personal information, it's crucial to understand how providers protect and use your data. As the person inputting data, you have legal and ethical responsibilities to protect personal and sensitive information.
            </p>
            <div className="mt-4">
              <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="text-blue-400 hover:text-blue-300 underline">
                Learn more about your GDPR responsibilities →
              </a>
            </div>
          </div>

          {/* Understanding Data Types */}
            {/* Understanding Data Types */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <h4 className="text-xl font-semibold text-blue-400 mb-6">Types of Sensitive Information</h4>
              
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
                <FlipCard 
                  frontTitle="Personal Data"
                  frontIcon={<UserCog className="h-6 w-6 text-blue-400" />}
                  frontContent={
                    <div className="space-y-4">
                      <div className="h-48 rounded-xl overflow-hidden">
                        <img 
                          src="https://images.pexels.com/photos/8369216/pexels-photo-8369216.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
                          alt="Personal Data Visualization"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <p className="text-slate-300">
                        Information that can identify an individual, either directly or indirectly. Understanding personal data is crucial for GDPR compliance.
                      </p>
                      <div className="mt-4 flex items-center justify-center">
                        <div className="group cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400/80 hover:text-blue-300 transition-colors">
                          <span className="group-hover:underline">Click to discover more</span>
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  }
                  backTitle="Personal Data "
                  backContent={
                    <div className="space-y-4">
                      <p className="text-slate-300">
                        According to the Information Commissioner's Office (ICO), personal data is any information that relates to an identified or identifiable individual. This includes both direct and indirect identification.
                      </p>
                      <div className="bg-slate-800/50 rounded-lg p-4 space-y-3">
                        <p className="text-sm text-slate-400">Examples include:</p>
                        <ul className="list-disc list-inside text-sm text-slate-400">
                          <li>Name and contact details</li>
                          <li>Online identifiers (IP addresses, cookie identifiers)</li>
                          <li>Location data</li>
                          <li>Employment history</li>
                          <li>Financial information</li>
                        </ul>
                        <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/personal-information-what-is-it/what-is-personal-information-a-guide/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 text-sm underline">
                          ICO Guide: What is Personal Information? →
                        </a>
                      </div>
                    </div>
                  }
                />

                <FlipCard 
                  frontTitle="Special Category Data"
                  frontIcon={<Shield className="h-6 w-6 text-blue-400" />}
                  frontContent={
                    <div className="space-y-4">
                      <div className="h-48 rounded-xl overflow-hidden">
                        <img 
                          src="https://images.pexels.com/photos/3938022/pexels-photo-3938022.jpeg" 
                          alt="Sensitive Data Protection"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <p className="text-slate-300">
                        Highly sensitive personal information that requires extra protection due to its potential impact on individual privacy and rights.
                      </p>
                      <div className="mt-4 flex items-center justify-center">
                        <div className="group cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400/80 hover:text-blue-300 transition-colors">
                          <span className="group-hover:underline">Click to explore protections</span>
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  }
                  backTitle="Special Category Data Details"
                  backContent={
                    <div className="space-y-4">
                      <p className="text-slate-300">
                        This type of data requires extra protection because it's more sensitive and could cause significant harm if misused.
                      </p>
                      <div className="bg-slate-800/50 rounded-lg p-4 space-y-3">
                        <p className="text-sm text-slate-400">Includes information about:</p>
                        <ul className="list-disc list-inside text-sm text-slate-400">
                          <li>Racial or ethnic origin</li>
                          <li>Political opinions</li>
                          <li>Religious beliefs</li>
                          <li>Trade union membership</li>
                          <li>Genetic and biometric data</li>
                          <li>Health data</li>
                          <li>Sexual orientation</li>
                        </ul>
                        <a href="https://ico.org.uk/for-organisations/direct-marketing-and-privacy-and-electronic-communications/guidance-for-the-use-of-personal-data-in-political-campaigning-1/special-category-data/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 text-sm underline">
                          ICO Guide: Special Category Data →
                        </a>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>

          {/* GDPR Awareness */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">The Seven GDPR Principles</h4>
            
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-slate-800/50 p-6 rounded-lg">
                  <h6 className="font-semibold text-blue-300 mb-2">1. Lawfulness, Fairness, and Transparency</h6>
                  <p className="text-sm text-slate-300">Process data legally, fairly, and with clear communication to individuals</p>
                </div>
                <div className="bg-slate-800/50 p-6 rounded-lg">
                  <h6 className="font-semibold text-blue-300 mb-2">2. Purpose Limitation</h6>
                  <p className="text-sm text-slate-300">Only collect data for specified, explicit, and legitimate purposes</p>
                </div>
                <div className="bg-slate-800/50 p-6 rounded-lg">
                  <h6 className="font-semibold text-blue-300 mb-2">3. Data Minimisation</h6>
                  <p className="text-sm text-slate-300">Only process what's necessary for your purpose</p>
                </div>
                <div className="bg-slate-800/50 p-6 rounded-lg">
                  <h6 className="font-semibold text-blue-300 mb-2">4. Accuracy</h6>
                  <p className="text-sm text-slate-300">Keep personal data accurate and up to date</p>
                </div>
                <div className="bg-slate-800/50 p-6 rounded-lg">
                  <h6 className="font-semibold text-blue-300 mb-2">5. Storage Limitation</h6>
                  <p className="text-sm text-slate-300">Don't keep data longer than necessary</p>
                </div>
                <div className="bg-slate-800/50 p-6 rounded-lg">
                  <h6 className="font-semibold text-blue-300 mb-2">6. Integrity and Confidentiality</h6>
                  <p className="text-sm text-slate-300">Ensure appropriate security of personal data</p>
                </div>
                <div className="bg-slate-800/50 p-6 rounded-lg col-span-full">
                  <h6 className="font-semibold text-blue-300 mb-2">7. Accountability</h6>
                  <p className="text-sm text-slate-300">Take responsibility for and demonstrate compliance with all these principles</p>
                </div>
              </div>

              <div className="bg-slate-900/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">GDPR in Practice with AI Models</h5>
                <div className="space-y-4">
                  <div className="border-l-4 border-red-500 pl-4">
                    <p className="text-slate-300 mb-2"><span className="text-red-400 font-semibold">Don't:</span> Paste an entire customer support email thread into ChatGPT, as it contains names, email addresses, and possibly sensitive information.</p>
                  </div>
                  <div className="border-l-4 border-green-500 pl-4">
                    <p className="text-slate-300"><span className="text-green-400 font-semibold">Do:</span> Remove all personal identifiers and only include the technical problem description when seeking AI assistance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Account Types */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Account Types and Data Protection</h4>
            
            <div className="space-y-6">
              <div className="bg-red-500/10 rounded-lg p-6 border border-red-500/20">
                <h5 className="font-semibold text-red-400 mb-4">Free Accounts: High Risk</h5>
                <div className="space-y-4">
                  <p className="text-slate-300">Free accounts typically:</p>
                  <ul className="list-disc list-inside text-slate-300 space-y-2">
                    <li>May use your data for model training</li>
                    <li>Often have limited privacy controls</li>
                    <li>May not offer data deletion guarantees</li>
                    <li>Could expose sensitive information to other users</li>
                    <li>May not be GDPR compliant for business use</li>
                  </ul>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Enterprise Accounts: Enhanced Protection</h5>
                <div className="space-y-4">
                  <p className="text-slate-300">Enterprise accounts often provide:</p>
                  <ul className="list-disc list-inside text-slate-300 space-y-2">
                    <li>Data processing agreements (DPAs)</li>
                    <li>No training on your data</li>
                    <li>Data residency options</li>
                    <li>Audit logs</li>
                    <li>Compliance certifications</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

{/* Provider Example: OpenAI */}
<div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
  <h4 className="text-xl font-semibold text-blue-400 mb-6">Real World Example: OpenAI's Trust Commitments</h4>
  
  <div className="space-y-6">
    <p className="text-slate-300">
      Let's look at how a major AI provider, OpenAI, demonstrates their commitment to protecting your data. You can find all this information on their{' '}
      <a href="https://trust.openai.com" 
         target="_blank" 
         rel="noopener noreferrer" 
         className="text-blue-400 hover:text-blue-300 underline">
        Trust Portal
      </a>.
    </p>
    
    <img 
      src="https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/openai_trust.png" 
      alt="OpenAI Trust Portal Screenshot"
      className="w-full rounded-lg object-cover mb-6"
    />
    
    <div className="bg-slate-800/50 rounded-lg p-6">
      <blockquote className="border-l-4 border-blue-500 pl-4 mb-6">
        <p className="text-slate-300">
          "Welcome to our Trust Portal for OpenAI's API, ChatGPT Enterprise, ChatGPT Team, and ChatGPT Edu services - your gateway to understanding our unwavering commitment to data security, privacy, and compliance. Here, you can access our comprehensive compliance documentation, find answers to frequently asked questions related to security and privacy, and explore our robust security practices. We believe in maintaining transparency and building trust with our customers, and this portal is designed to provide you with the information and assurance you need to feel confident in our ability to protect your data."
        </p>
        <footer className="mt-2 text-sm text-slate-400">
          - Source: OpenAI Trust Portal
        </footer>
      </blockquote>

      <div className="space-y-8">
        <div className="bg-slate-900/50 p-6 rounded-lg">
          <h6 className="font-semibold text-blue-300 mb-4">SOC 2 Type 2: Independent Security Verification</h6>
          <div className="space-y-3">
            <p className="text-slate-300">Think of this like a thorough safety inspection for your home, but for data. An independent auditor has verified that OpenAI:</p>
            <ul className="list-disc list-inside text-slate-300 ml-4">
              <li>Keeps your data safe from unauthorised access</li>
              <li>Has systems that work reliably when you need them</li>
              <li>Handles your information exactly as they promise</li>
              <li>Protects your privacy</li>
            </ul>
            <p className="text-slate-300 mt-4">
              <span className="font-semibold text-blue-300">What this means for you:</span> Just like you'd trust a bank that's been independently audited, this certification means OpenAI's security has been thoroughly checked by experts.
            </p>
          </div>
        </div>
        
        <div className="bg-slate-900/50 p-6 rounded-lg">
          <h6 className="font-semibold text-blue-300 mb-4">GDPR Compliance: Your Data Rights</h6>
          <div className="space-y-3">
            <p className="text-slate-300">This is like a bill of rights for your personal data in Europe, but OpenAI applies these principles globally. It means:</p>
            <ul className="list-disc list-inside text-slate-300 ml-4">
              <li>You can request to see what data they have about you</li>
              <li>You can ask them to delete your data</li>
              <li>They must tell you if your data's security is ever compromised</li>
              <li>They can't use your data for purposes you haven't agreed to</li>
            </ul>
            <p className="text-slate-300 mt-4">
              <span className="font-semibold text-blue-300">What this means for you:</span> Like having tenant rights when renting a property, GDPR gives you specific rights over your personal data.
            </p>


            <div className="bg-red-500/10 rounded-lg p-6 border border-red-500/20">
                <h5 className="font-semibold text-red-400 mb-4">EU / UK GDPR</h5>
                <div className="space-y-4">
                  <p className="text-slate-300"> If setting up an enterprise account to work with personal or special data use the contact links found on the page to verify your region is complient. It is likely a Data Protection Agreement (DPA) will be set up in this case. <em> We can sign a DPA with customers to support their compliance with GDPR. Please complete our DPA form (https://ironcladapp.com/public-launch/63ffefa2bed6885f4536d0fe) to execute a DPA with OpenAI.</em></p>
          </div>
          </div>


          </div>
        </div>
        
        <div className="bg-slate-900/50 p-6 rounded-lg">
          <h6 className="font-semibold text-blue-300 mb-4">CSA STAR: Cloud Security Promise</h6>
          <div className="space-y-3">
            <p className="text-slate-300">This is similar to a safety rating for cloud services, like a hygiene rating for restaurants. It shows OpenAI:</p>
            <ul className="list-disc list-inside text-slate-300 ml-4">
              <li>Follows industry best practices for cloud security</li>
              <li>Regularly checks for and fixes security risks</li>
              <li>Has plans in place to handle security problems</li>
              <li>Carefully manages who can access your data</li>
            </ul>
            <p className="text-slate-300 mt-4">
              <span className="font-semibold text-blue-300">What this means for you:</span> Just as you'd prefer to eat at a restaurant with a high hygiene rating, this certification shows OpenAI meets high standards for keeping your data safe in the cloud.
            </p>
          </div>
        </div>

        <div className="bg-slate-900/50 p-6 rounded-lg">
          <h6 className="font-semibold text-blue-300 mb-4">Available Documentation</h6>
          <p className="text-slate-300 mb-4">On the Trust Portal, you can find easy-to-understand information about:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-slate-800/50 p-4 rounded-lg">
              <h7 className="font-semibold text-blue-300">Security Measures</h7>
              <p className="text-sm text-slate-300 mt-1">How they protect your data, written in clear language</p>
            </div>
            <div className="bg-slate-800/50 p-4 rounded-lg">
              <h7 className="font-semibold text-blue-300">Privacy Policies</h7>
              <p className="text-sm text-slate-300 mt-1">Your rights and how they handle your information</p>
            </div>
            <div className="bg-slate-800/50 p-4 rounded-lg">
              <h7 className="font-semibold text-blue-300">Safety Procedures</h7>
              <p className="text-sm text-slate-300 mt-1">What happens if something goes wrong</p>
            </div>
            <div className="bg-slate-800/50 p-4 rounded-lg">
              <h7 className="font-semibold text-blue-300">Compliance Proof</h7>
              <p className="text-sm text-slate-300 mt-1">Evidence they meet global security standards</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          {/* Summary */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
            <div className="flex items-center space-x-3 mb-4">
              <CheckCircle className="w-6 h-6 text-blue-400" />
              <h4 className="text-lg font-semibold text-blue-400">Key Takeaways</h4>
            </div>
            <ul className="space-y-2 text-slate-300">
              <li>• Always be cautious with personal and sensitive data</li>
              <li>• Review provider security measures before sharing information</li>
              <li>• Understand your responsibilities under data protection laws</li>
              <li>• Use enterprise accounts for business-critical data</li>
              <li>• Regularly review provider compliance updates</li>
            </ul>
          </div>
        </div>
      )
    },

    guardrails: {
      title: 'Guardrails and Content Filtering',
      content: (
        <div className="space-y-8">
          {/* Introduction */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Understanding LLM Guardrails
            </h3>
            <p className="text-slate-300">
              Think of guardrails like safety barriers on a mountain road - they help keep everyone safe while still allowing the journey to continue. For AI language models, guardrails are protective measures that help ensure safe and appropriate interactions.
            </p>
          </div>

          {/* Why Guardrails Exist */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Why Do We Need Guardrails?</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Protection Against Misuse</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>• Prevent harmful content generation</li>
                  <li>• Avoid inappropriate responses</li>
                  <li>• Protect user privacy</li>
                  <li>• Maintain ethical boundaries</li>
                </ul>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Business Benefits</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>• Build user trust</li>
                  <li>• Reduce legal risks</li>
                  <li>• Ensure consistent responses</li>
                  <li>• Protect brand reputation</li>
                </ul>
              </div>
            </div>

            <div className="bg-slate-800/50 rounded-xl p-6">
              <img 
                src="https://images.pexels.com/photos/16414457/pexels-photo-16414457/free-photo-of-a-red-car-on-a-road.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="Guardrails Visualization"
                className="w-full rounded-lg object-cover mb-4"
              />
              <p className="text-sm text-slate-400 text-center">
                
              </p>
            </div>
          </div>

          {/* Types of Guardrails */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Types of Guardrails</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Content Filtering</h5>
                <p className="text-slate-300">Like a spam filter for emails, this catches and blocks inappropriate or harmful content before it reaches users.</p>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Topic Restrictions</h5>
                <p className="text-slate-300">Similar to parental controls on TV, this prevents discussion of certain sensitive or inappropriate topics.</p>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Behavior Controls</h5>
                <p className="text-slate-300">Like having a responsible adult present, this ensures the AI maintains appropriate behavior and tone.</p>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Output Limitation</h5>
                <p className="text-slate-300">Sets boundaries on what kind of information or content the AI can generate, like having an age rating system.</p>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Input Validation</h5>
                <p className="text-slate-300">Checks user requests to ensure they're appropriate and safe, like a bouncer at a club.</p>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Safety Monitoring</h5>
                <p className="text-slate-300">Continuously watches for potential misuse or safety issues, like a security camera system.</p>
              </div>
            </div>
          </div>

          {/* Jailbreaking Section */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Understanding Jailbreak Attempts</h4>
            
            <div className="space-y-6">
              <div className="bg-red-500/10 rounded-lg p-6 border border-red-500/20">
                <h5 className="font-semibold text-red-400 mb-4">What is Jailbreaking?</h5>
                <p className="text-slate-300">
                  Jailbreaking is when someone tries to bypass an AI's safety measures - like trying to climb over those mountain road guardrails we mentioned earlier. It's usually done by giving the AI complex or confusing instructions that try to override its safety settings.
                </p>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h5 className="font-semibold text-blue-300 mb-4">Early Examples</h5>
                  <p className="text-slate-300 mb-4">
                    One of the earliest jailbreak attempts was the "DAN" (Do Anything Now) prompt, where users would tell the AI to pretend to be a version of itself without rules. This is like telling someone "pretend you're in a world without laws" - it attempts to bypass ethical guidelines through role-play.
                  </p>
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-sm text-slate-400">
                      While we won't share specific jailbreak instructions, it's important to understand that these attempts can pose serious risks to both users and AI systems.
                    </p>
                  </div>
                </div>

                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h5 className="font-semibold text-blue-300 mb-4">Why It's Problematic</h5>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Bypasses important safety measures</li>
                    <li>• Could lead to harmful or inappropriate content</li>
                    <li>• May expose users to unreliable information</li>
                    <li>• Often violates terms of service</li>
                    <li>• Can result in account suspension</li>
                  </ul>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-xl p-6">
                <img 
                  src="https://images.pexels.com/photos/1309902/pexels-photo-1309902.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="Jailbreak Prevention Visualization"
                  className="w-full rounded-lg object-cover mb-4"
                />
                <p className="text-sm text-slate-400 text-center">
                 
                </p>
              </div>

              <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <Shield className="w-6 h-6 text-blue-400" />
                  <h4 className="text-lg font-semibold text-blue-400">Provider Response</h4>
                </div>
                <p className="text-slate-300">
                  AI providers continuously update and strengthen their guardrails to protect against jailbreak attempts. They monitor for new types of bypass attempts and regularly improve their safety systems, much like how banks constantly update their security measures against new types of fraud.
                </p>
                <br></br>

                <div className="bg-red-500/10 rounded-lg p-6 border border-red-500/20">
              
                <div className="space-y-4">
                  <p className="text-slate-300"> Currently, the safety guidelines—or ‘guardrails’—for large language models (LLMs) are set by the companies that develop them. These guardrails reflect the cultural and societal norms of each provider, meaning that the approach to safety and appropriate use can vary significantly between models. As there is no industry standard, users may experience differences in content moderation and ethical considerations depending on the model in use.</p>
          </div>
          </div>


              </div>
            </div>
          </div>

          {/* Best Practices Summary */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
            <div className="flex items-center space-x-3 mb-4">
              <CheckCircle className="w-6 h-6 text-blue-400" />
              <h4 className="text-lg font-semibold text-blue-400">Responsible Usage Tips</h4>
            </div>
            <ul className="space-y-2 text-slate-300">
              <li>• Respect the AI's ethical boundaries</li>
              <li>• Use AI systems for their intended purpose</li>
              <li>• Understand that guardrails exist for everyone's protection</li>
              <li>• Keep conversations within appropriate topics</li>
              <li>• Be mindful of the LLMs country of origin and how this could effect the guardrails you experience</li>
            </ul>
          </div>
        </div>
      )
    },

    hallucinations: {
      title: 'Understanding AI Hallucinations',
      content: (
        <div className="space-y-8">
          {/* Introduction */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              What Are AI Hallucinations?
            </h3>
            <p className="text-slate-300">
              When we talk about AI 'hallucinations', we're actually describing something more like confabulation - where the AI creates plausible but incorrect information to fill gaps in its knowledge. Think of it like a student making up an answer in an exam when they can't remember the correct information, rather than seeing things that aren't there.
            </p>
          </div>

          {/* Understanding Why */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Why Do AIs Hallucinate?</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">The Pattern Matching Challenge</h5>
                <p className="text-slate-300">
                  Imagine you're trying to complete a puzzle with some pieces missing. You might find pieces that seem to fit based on their shape, but the picture isn't quite right. AI models work similarly - they look for patterns in their training data and try to complete the 'puzzle' of your request, sometimes forcing connections that aren't quite correct.
                </p>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">The Confidence Problem</h5>
                <p className="text-slate-300">
                  AI models are designed to always provide an answer, even when they're not sure. It's like asking someone directions in a foreign city - they might give you a confident answer just to be helpful, even if they're not entirely sure it's correct.
                </p>
              </div>
            </div>

            <div className="bg-slate-800/50 rounded-xl p-6">
              <img 
                src="https://images.pexels.com/photos/1046209/pexels-photo-1046209.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="AI Hallucination Visualization"
                className="w-full rounded-lg object-cover mb-4"
              />
              <p className="text-sm text-slate-400 text-center">
                How AI models piece together information from their training data
              </p>
            </div>
          </div>

          {/* Real World Example */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">A Real-World Example</h4>
            
            <div className="space-y-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">The Legal Case Incident</h5>
                <p className="text-slate-300 mb-4">
                  In 2023, a lawyer used ChatGPT to research legal cases and included several in their court filing. However, these cases were completely fabricated by the AI. The model had created convincing-sounding but entirely fictional case names, dates, and legal conclusions.
                </p>
                <div className="bg-slate-900/50 p-4 rounded-lg">
                  <p className="text-sm text-slate-400">
                    Why did this happen? The AI combined its knowledge of legal language, case naming conventions, and common legal principles to create what seemed like real cases. It's like someone mixing up real memories with plots from legal TV shows - the format is right, but the content isn't real.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Common Areas for Hallucinations */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Where Do Hallucinations Commonly Occur?</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Historical Facts</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Dates and timelines</li>
                  <li>• Names of people or places</li>
                  <li>• Specific events</li>
                </ul>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Technical Information</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Scientific data</li>
                  <li>• Statistical figures</li>
                  <li>• Technical specifications</li>
                </ul>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Citations</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Research papers</li>
                  <li>• Book references</li>
                  <li>• Source attributions</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Identifying Hallucinations */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">How to Spot Hallucinations</h4>
            
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h5 className="font-semibold text-blue-300 mb-4">Warning Signs</h5>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Inconsistent information within the same response</li>
                    <li>• Very specific details without clear sources</li>
                    <li>• Information that seems too perfect or convenient</li>
                    <li>• Claims that sound improbable or extraordinary</li>
                  </ul>
                </div>
                
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h5 className="font-semibold text-blue-300 mb-4">Verification Tips</h5>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Cross-check important facts</li>
                    <li>• Look for primary sources</li>
                    <li>• Be skeptical of precise numbers</li>
                    <li>• Verify citations and references</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Future Outlook */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">The Road Ahead</h4>
            
            <div className="space-y-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <p className="text-slate-300">
                  AI labs are actively working to reduce hallucinations through various approaches:
                </p>
                <ul className="mt-4 space-y-2 text-slate-300">
                  <li>• Better training data quality</li>
                  <li>• Improved fact-checking mechanisms</li>
                  <li>• Enhanced context understanding</li>
                  <li>• More robust verification systems</li>
                </ul>
              </div>

              <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <CheckCircle className="w-6 h-6 text-blue-400" />
                  <h4 className="text-lg font-semibold text-blue-400">Working Safely with AI</h4>
                </div>
                <ul className="space-y-2 text-slate-300">
                  <li>• Always verify critical information</li>
                  <li>• Use AI as a starting point, not the final authority</li>
                  <li>• Keep records of sources and fact-checks</li>
                  <li>• Be transparent about AI usage in your work</li>
                  <li>• Stay updated on AI capabilities and limitations</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Next Section Preview */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <p className="text-slate-300">
              In the next chapter, we'll explore specific prompting techniques that can help reduce hallucinations and improve the reliability of AI responses.
            </p>
          </div>
        </div>
      )
    },

      summary: {
        title: 'Summary: Key Takeaways',
        content: (
          <div className="space-y-8">
            {/* Introduction */}
            <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
              <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
                Responsible Use of Large Language Models
              </h3>
              <p className="text-slate-300">
                As we've explored throughout this module, working with LLMs requires understanding both their capabilities and limitations to ensure safe and effective use.
              </p>
            </div>
    
            {/* Key Topics Summary */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h4 className="text-xl font-semibold text-blue-400 mb-4">Data Protection & Privacy</h4>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Understand your responsibilities under GDPR</li>
                    <li>• Be cautious with personal and sensitive data</li>
                    <li>• Use enterprise accounts for business data</li>
                    <li>• Always verify provider security measures</li>
                    <li>• Regular review of data handling practices</li>
                  </ul>
                </div>
    
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h4 className="text-xl font-semibold text-blue-400 mb-4">Security & Trust</h4>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Review provider compliance certifications</li>
                    <li>• Understand data usage policies</li>
                    <li>• Implement appropriate guardrails</li>
                    <li>• Monitor for potential misuse</li>
                    <li>• Stay informed about security updates</li>
                  </ul>
                </div>
              </div>
    
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h4 className="text-xl font-semibold text-blue-400 mb-4">Guardrails & Safety</h4>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Implement content filtering</li>
                    <li>• Set clear usage boundaries</li>
                    <li>• Monitor for jailbreak attempts</li>
                    <li>• Regular safety audits</li>
                    <li>• Update protection measures</li>
                  </ul>
                </div>
    
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <h4 className="text-xl font-semibold text-blue-400 mb-4">Managing Hallucinations</h4>
                  <ul className="space-y-3 text-slate-300">
                    <li>• Verify important information</li>
                    <li>• Use fact-checking procedures</li>
                    <li>• Implement verification steps</li>
                    <li>• Document AI-generated content</li>
                    <li>• Stay aware of common issues</li>
                  </ul>
                </div>
              </div>
            </div>
    
            {/* Best Practices */}
            <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
              <div className="flex items-center space-x-3 mb-4">
                <CheckCircle className="w-6 h-6 text-blue-400" />
                <h4 className="text-lg font-semibold text-blue-400">Going Forward</h4>
              </div>
              <ul className="space-y-2 text-slate-300">
                <li>• For business, develop clear policies for LLM use</li>
                <li>• Keep up with AI safety developments</li>
                <li>• Document and learn from any incidents and AI behviour</li>
              </ul>
            </div>
          </div>
        )
      },
    
      quiz: {
        title: 'Knowledge Check',
        content: (
          <div className="space-y-6">
            <p className="text-gray-300 mb-6">
              Let's test your understanding of LLM security, policies, and safety considerations.
              Choose the best answer for each question.
            </p>
            
            <Quiz questions={[
            {
              questionText: "What is an LLM hallucination?",
              answerOptions: [
                "When the model crashes unexpectedly",
                "When the model runs too slowly",
                "When the model generates plausible but incorrect information",
                "When the model needs updating"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "LLM hallucination occurs when the model generates convincing but factually incorrect information. This happens when the model attempts to fill gaps in its knowledge with plausible-sounding but inaccurate content.",
                incorrect: {
                  0: "Model crashes are technical failures, not hallucinations. They're related to system performance rather than content generation.",
                  1: "Slow performance is a technical issue related to processing speed, not the accuracy or truthfulness of responses.",
                  3: "Model updates improve capabilities but aren't related to hallucinations, which occur regardless of model version."
                }
              }
            },
            {
              questionText: "Which of these is considered special category (sensitive) data under GDPR?",
              answerOptions: [
                "Public social media posts",
                "Company contact information",
                "Health and medical information",
                "Product descriptions"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Health and medical information is classified as special category data under GDPR, requiring extra protection due to its sensitive nature and potential impact on individual privacy.",
                incorrect: {
                  0: "Public social media posts are already in the public domain and aren't considered special category data.",
                  1: "Company contact information is personal data but not special category data under GDPR.",
                  3: "Product descriptions typically don't contain personal data and aren't covered by special category protections."
                }
              }
            },
            {
              questionText: "What is a key indicator that an LLM might be hallucinating?",
              answerOptions: [
                "It gives very specific details without sources",
                "It responds quickly",
                "It uses technical language",
                "It asks clarifying questions"
              ],
              correctAnswer: 0,
              explanations: {
                correct: "When an LLM provides very specific details without citing sources, it's often a sign of hallucination. This over-specificity without verification is a red flag for fabricated information.",
                incorrect: {
                  1: "Response speed isn't related to accuracy or hallucination - fast responses can be either accurate or inaccurate.",
                  2: "Technical language use doesn't indicate hallucination - it might be appropriate for the context.",
                  3: "Asking clarifying questions actually suggests the model is being careful and thorough, not hallucinating."
                }
              }
            },
            {
              questionText: "What is the purpose of LLM guardrails?",
              answerOptions: [
                "To increase model speed",
                "To reduce operational costs",
                "To ensure safe and appropriate interactions",
                "To improve grammar"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Guardrails are implemented to ensure safe and appropriate interactions, protecting users from harmful content and ensuring the model operates within ethical boundaries.",
                incorrect: {
                  0: "Model speed is a performance metric, not related to safety guardrails.",
                  1: "Operational costs are a business consideration, not the purpose of guardrails.",
                  3: "Grammar improvement is a language processing feature, not a safety measure."
                }
              }
            },
            {
              questionText: "When using customer data with LLMs, what is the best practice?",
              answerOptions: [
                "Remove all personal identifiers first",
                "Use it exactly as received",
                "Share it across multiple LLMs",
                "Store it indefinitely"
              ],
              correctAnswer: 0,
              explanations: {
                correct: "Removing personal identifiers before using customer data with LLMs is essential for privacy protection and compliance with data protection regulations.",
                incorrect: {
                  1: "Using raw customer data creates privacy risks and may violate data protection laws.",
                  2: "Sharing across multiple LLMs increases privacy risks and potential data exposure.",
                  3: "Indefinite storage violates data minimisation principles and creates unnecessary risks."
                }
              }
            },
            {
              questionText: "What does jailbreaking attempt to do?",
              answerOptions: [
                "Improve model performance",
                "Update the model",
                "Bypass safety measures",
                "Fix technical issues"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Jailbreaking attempts to bypass the safety measures and ethical guardrails built into LLMs, potentially enabling harmful or inappropriate responses.",
                incorrect: {
                  0: "Jailbreaking doesn't improve performance; it compromises safety features.",
                  1: "Model updates are legitimate improvements, unlike jailbreaking attempts.",
                  3: "Technical issues require proper maintenance, not safety measure bypasses."
                }
              }
            },
            {
              questionText: "Which account type typically offers the strongest data protection?",
              answerOptions: [
                "Free accounts",
                "Basic paid accounts",
                "Enterprise accounts",
                "Guest accounts"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Enterprise accounts offer the strongest data protection, including features like enhanced security measures, dedicated support, and customised privacy controls.",
                incorrect: {
                  0: "Free accounts typically offer basic security features only.",
                  1: "Basic paid accounts have improved but not enterprise-grade protection.",
                  3: "Guest accounts often have minimal security features and protections."
                }
              }
            },
            {
              questionText: "What is a key GDPR principle regarding data collection?",
              answerOptions: [
                "Collect as much data as possible",
                "Only collect necessary data",
                "Always keep data indefinitely",
                "Share data widely"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "Data minimisation is a key GDPR principle - only collecting data that is necessary for specific, stated purposes helps protect privacy and reduce risks.",
                incorrect: {
                  0: "Collecting excessive data violates GDPR's data minimisation principle.",
                  2: "Indefinite retention violates storage limitation principles under GDPR.",
                  3: "Wide data sharing increases privacy risks and potentially violates GDPR."
                }
              }
            },
            {
              questionText: "How can you best verify the accuracy of LLM responses?",
              answerOptions: [
                "Trust all responses completely",
                "Cross-check important information with reliable sources",
                "Ask the same question multiple times",
                "Use multiple LLMs simultaneously"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "Cross-checking information with reliable sources is the most effective way to verify LLM responses, ensuring accuracy through independent verification.",
                incorrect: {
                  0: "Blindly trusting responses ignores the possibility of hallucinations or errors.",
                  2: "Asking repeatedly doesn't guarantee accuracy - the model might consistently hallucinate.",
                  3: "Using multiple LLMs doesn't guarantee accuracy as they might all hallucinate."
                }
              }
            },
            {
              questionText: "What should you do if you suspect an LLM is hallucinating?",
              answerOptions: [
                "Continue using the information anyway",
                "Immediately report the model as broken",
                "Verify the information with reliable sources",
                "Switch to a different LLM"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "When hallucination is suspected, verifying information with reliable sources is the appropriate response to ensure accuracy and prevent the spread of misinformation.",
                incorrect: {
                  0: "Using potentially incorrect information could lead to serious consequences.",
                  1: "The model isn't broken - hallucinations are a known limitation of LLMs.",
                  3: "Switching LLMs doesn't address the need to verify the specific information."
                }
              }
            }
            ]} 
            
         
          onQuizComplete={() => {
            console.log("Quiz completion callback triggered");
            setIsQuizComplete(true);
          }}
            />
          </div>
        )
      }

    }

    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
        <div className="lg:hidden fixed top-4 right-4 z-50">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 bg-slate-800 rounded-xl border border-slate-700 hover:bg-slate-700 transition-colors"
          >
            <Menu size={24} />
          </button>
        </div>
    
        <div className={`fixed top-0 left-0 h-full bg-slate-900/95 backdrop-blur-md border-r border-slate-800/50 w-64 transform transition-transform duration-200 ease-in-out z-40 overflow-y-auto
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
        >
          <div className="p-6 space-y-6">
            <div className="flex justify-center mb-6">
              <img 
                src="/logo.png" 
                alt="AI DATA CERT Logo" 
                className="h-24 w-auto"
              />
            </div>
    
            <Link 
              to="/llm_user_mastery"
              className="inline-flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors group"
            >
              <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
              <span>Back to Modules</span>
            </Link>
    
            <nav className="space-y-1">
              {sections.map((section) => (
                <button
                  key={section.id}
                  onClick={() => setActiveSection(section.id)}
                  className={`w-full text-left px-4 py-3 rounded-xl transition-all flex items-center space-x-3
                    ${activeSection === section.id 
                      ? 'bg-blue-500/20 text-blue-400 border border-blue-500/50 shadow-lg shadow-blue-500/20' 
                      : 'hover:bg-slate-800/50 text-slate-300'}`}
                >
                  <section.icon size={18} />
                  <span>{section.title}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
    
        <div className="lg:pl-64">
          <div className="container mx-auto px-4 py-8">
            <div className="max-w-4xl mx-auto">
              <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl shadow-xl border border-slate-700/30 p-8 mb-8">
                <div className="border-b border-slate-700 pb-6 mb-6">
                  <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                    LLM Security and Responsible use
                  </h1>
                  <h2 className="text-xl text-blue-400">AI DATA CERT</h2>
                </div>
    
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                    {contentSections[activeSection]?.title}
                  </h3>
                  {contentSections[activeSection]?.content}
                </div>
              </div>
    
              {/* Next Module Button - Only show when on quiz section and quiz is complete */}
              {activeSection === 'quiz' && isQuizComplete && (
                <div className="mt-8 flex justify-end">
                  <Link 
                    to="/llm_user_mastery_1_5_prompting"
                    className="inline-flex items-center space-x-2 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-xl transition-colors shadow-lg shadow-blue-500/20 group"
                  >
                    <span>1.4 Complete - Press to start 1.5</span>
                    <ArrowRight size={20} className="group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              )}
    
            </div>
          </div>
        </div>
      </div>
    );
    };
    
    export default Onepfour;