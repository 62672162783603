import React, { useState, useEffect } from 'react';
import { ArrowLeft, Menu, Search, FileText, Brain, Database, Image, Code, BookOpen, CheckCircle, Info, BarChart2, Link as LinkIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

const Onepsix = () => {
  const [activeSection, setActiveSection] = useState('quick-wins');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  
  useEffect(() => {
    // Immediate scroll to top without smooth behavior on initial load
    window.scrollTo(0, 0);
  }, []); 


  
  // Add a second useEffect to handle section changes
  useEffect(() => {
    // Smooth scroll to top when section changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeSection]);


  const handleSectionChange = (sectionId) => {
    // First change the section
    setActiveSection(sectionId);
    setIsMobileMenuOpen(false);
    
    // Then scroll to top with a slight delay to ensure the new content is rendered
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  };

  const sections = [
    { id: 'introduction', title: 'Introduction', icon: Info },
    { id: 'quick-wins', title: 'Quick Wins', icon: Search },
    { id: 'questioning', title: 'Document Analysis', icon: FileText },
    { id: 'custom-outputs', title: 'Custom Outputs', icon: Database },
    { id: 'image-recognition', title: 'Image Recognition and Generation', icon: Image },
    { id: 'data-analytics', title: 'Data Analytics', icon: BarChart2 },
    { id: 'notebook-lm', title: 'Notebook LM', icon: BookOpen },
    { id: 'artifacts', title: 'Claude Artifacts', icon: Code },
    { id: 'integrations', title: 'Integrations', icon: LinkIcon },
    {
      id: 'course-complete',
      title: 'Course Complete',
      icon: CheckCircle,
      customStyle: `bg-[repeating-conic-gradient(black_0_90deg,white_0_180deg)_0_0/20px_20px] bg-opacity-20 text-white border border-white/20`
    }
  ];

  const contentSections = {
    'introduction': {
      title: 'Workshop Preview',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Welcome to the LLM Features Workshop
            </h3>
            
            <p className="text-slate-300 mb-6">
              In this hands-on workshop, we'll explore the practical applications of Large Language Models across various business scenarios. From quick wins to complex integrations, you'll learn how to leverage these powerful tools effectively.
            </p>

            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Today's Journey</h4>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="space-y-3">
                    <div className="flex items-start space-x-3">
                      <Search className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Quick Wins</h5>
                        <p className="text-sm text-slate-400">Immediate value from web searches and current events analysis</p>
                      </div>
                    </div>
                    <div className="flex items-start space-x-3">
                      <FileText className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Document Analysis</h5>
                        <p className="text-sm text-slate-400">Deep dive into financial reports and complex documentation</p>
                      </div>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Database className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Custom Outputs</h5>
                        <p className="text-sm text-slate-400">Generate structured data and automate analysis</p>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <div className="flex items-start space-x-3">
                      <Image className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Image Recognition</h5>
                        <p className="text-sm text-slate-400">Transform documents into structured data</p>
                      </div>
                    </div>

                    <div className="flex items-start space-x-3">
                      <BarChart2 className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Data Analytics</h5>
                        <p className="text-sm text-slate-400">Get your LLM to conduct data analytics</p>
                      </div>
                    </div>

                    <div className="flex items-start space-x-3">
                      <Code className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Claude Artifacts</h5>
                        <p className="text-sm text-slate-400">Create apps, dashboards, and diagrams</p>
                      </div>
                    </div>
                    <div className="flex items-start space-x-3">
                      <LinkIcon className="w-5 h-5 text-blue-400 mt-1" />
                      <div>
                        <h5 className="font-medium text-blue-300">Integrations</h5>
                        <p className="text-sm text-slate-400">Microsoft Copilot and Google Workspace features</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">What to Expect</h4>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Hands-on exercises with real-world applications",
                    "Live demonstrations of each feature",
                    "Interactive Q&A sessions",
                    "Practical tips for implementation",
                    "Best practices for enterprise use"
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    },

    
    'quick-wins': {
      title: 'Quick Wins with LLMs',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
              Immediate Value from LLMs
            </h3>
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Web Search Analysis</h4>
                <p className="text-slate-300 mb-4">
                  Learn how to effectively use LLMs to analyse and summarize web search results, including:
                </p>
                <div className="bg-slate-900/40 p-4 rounded-lg border border-slate-700/30">
                  <pre className="text-sm text-slate-300">
                    "Use web search to collect up to date infomation on ..."
                  </pre>
                </div>
              </div>

            
            </div>
          </div>
        </div>
      )
    },
    
    'questioning': {
      title: 'Document Analysis & Questioning',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Financial Report Analysis</h4>
                <p className="text-slate-300 mb-4">
                  Example: analysing a quarterly financial report
                </p>
                <div className="bg-slate-900/40 p-4 rounded-lg border border-slate-700/30">
                  <pre className="text-sm text-slate-300">
                    "What are the key performance indicators showing improving trends in this report?"
                  </pre>
                </div>
              </div>

             
            </div>
          </div>
        </div>
      )
    },

    'custom-outputs': {
      title: 'Custom Outputs',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Structured Data Generation</h4>
                <p className="text-slate-300 mb-4">Generate various file formats from natural language:</p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "CSV files for data analysis",
                    "Calendar (.ics) files for scheduling",
                    "JSON structures for API integration",
                    "Markdown documentation"
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Data Analysis Automation</h4>
                <p className="text-slate-300 mb-4">
                  Examples of automated analysis capabilities:
                </p>
                <div className="bg-slate-900/40 p-4 rounded-lg border border-slate-700/30">
                  <pre className="text-sm text-slate-300">
                    "Generate a CSV file based on the results extracted ..."
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },

    'image-recognition': {
      title: 'Image Recognition & OCR',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">OCR Capabilities</h4>
                <p className="text-slate-300 mb-4">
                  Transform images into structured data:
                </p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Form data extraction",
                    "Receipt processing",
                    "Document digitisation"
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>


              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Image Generation</h4>
                <p className="text-slate-300 mb-4">
                  Transform images into structured data:
                </p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Company logos",
                    "presentation images",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>


            </div>
          </div>
        </div>
      )
    },


    'data-analytics': {
      title: 'Automated Data Analytics',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Automated Analysis Capabilities</h4>
                <p className="text-slate-300 mb-4">
                  Leverage LLMs for sophisticated data analysis:
                </p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Extracting insights from your data",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Example Applications</h4>
                <div className="bg-slate-900/40 p-4 rounded-lg border border-slate-700/30">
                  <pre className="text-sm text-slate-300">
                    "analyse this sales dataset and identify:...
                  </pre>
                </div>
                <div className="mt-4 space-y-2">
                  <h5 className="font-medium text-blue-300">Common Use Cases:</h5>
                  <ul className="space-y-2 text-slate-300">
                    {[
                      "Sales performance analysis",
                      "Customer behavior patterns",
                      "Market trend identification",
                      "Operational efficiency metrics"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start space-x-2">
                        <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                          <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                        </div>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },



    'artifacts': {
      title: 'Claude Artifacts',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Creating with Claude</h4>
                <p className="text-slate-300 mb-4">
                  Explore Claude's creative capabilities:
                </p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Interactive web applications",
                    "Data visualisation dashboards",
                    "Process flow charts"
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    },

    'notebook-lm': {
      title: 'Notebook LM Features',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Research Synthesis</h4>
                <p className="text-slate-300 mb-4">
                  Streamline research by combining multiple information sources:
                </p>
                <div className="bg-slate-900/40 p-4 rounded-lg border border-slate-700/30">
                  <pre className="text-sm text-slate-300">
                    "Analyse these papers and ...."
                  </pre>
                </div>
              </div>

              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Podcast Generation</h4>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Convert research into engaging scripts",
                    "Generate episode summaries",
                    "Create show notes with key points",
                    "Develop guest questions"
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

      )
    },
    
    

    'integrations': {
      title: 'Service Integrations',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="space-y-6">
              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Microsoft Copilot Integration</h4>
                <p className="text-slate-300 mb-4">
                  Enhance productivity across Microsoft applications:
                </p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Integration with word powerpoint outlook and excel",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                <h4 className="text-lg font-semibold text-blue-400 mb-4">Google Workspace Integration</h4>
                <p className="text-slate-300 mb-4">
                  Gemini integration features:
                </p>
                <ul className="space-y-2 text-slate-300">
                  {[
                    "Integration of Gemini with other google features "
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    },


      'course-complete': {
        title: 'Congratulations on Completing LLM User Mastery',
        content: (
          <div className="space-y-8">
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8 relative overflow-hidden">
              {/* Fireworks Animation */}
              <div className="absolute inset-0">
                <div className="animate-firework-1 absolute w-2 h-2 bg-blue-500 rounded-full" style={{ left: '20%', top: '20%' }}></div>
                <div className="animate-firework-2 absolute w-2 h-2 bg-red-500 rounded-full" style={{ left: '40%', top: '30%' }}></div>
                <div className="animate-firework-3 absolute w-2 h-2 bg-green-500 rounded-full" style={{ left: '60%', top: '25%' }}></div>
                <div className="animate-firework-4 absolute w-2 h-2 bg-yellow-500 rounded-full" style={{ left: '80%', top: '35%' }}></div>
                <div className="animate-firework-5 absolute w-2 h-2 bg-purple-500 rounded-full" style={{ left: '30%', top: '40%' }}></div>
                <div className="animate-firework-6 absolute w-2 h-2 bg-pink-500 rounded-full" style={{ left: '70%', top: '45%' }}></div>
              </div>
              
              <div className="relative z-10">
                <h3 className="text-3xl font-bold text-white mb-6 text-center">
                  🎉 Congratulations! 🎉
                </h3>
                <p className="text-xl text-center text-white mb-8">
                  You've completed the LLM User Mastery Course
                </p>
                <p className="text-1xl font-semibold text-white mb-4 text-center">
                  Check out{' '}
                  <a 
                    href="https://aidatacert.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 underline transition-colors"
                  >
                    AIDATACERT.COM
                  </a>
                  {' '}for further courses
                </p>
              </div>
            </div>
          </div>
        )
      }


  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
      <div className="lg:hidden fixed top-4 right-4 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 bg-slate-800 rounded-xl border border-slate-700 hover:bg-slate-700 transition-colors"
        >
          <Menu size={24} />
        </button>
      </div>

      <div className={`fixed top-0 left-0 h-full bg-slate-900/95 backdrop-blur-md border-r border-slate-800/50 w-64 transform transition-transform duration-200 ease-in-out z-40 overflow-y-auto
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
      >
        <div className="p-6 space-y-6">
          <div className="flex justify-center mb-6">
            <img 
              src="/logo.png" 
              alt="AI DATA CERT Logo" 
              className="h-24 w-auto"
            />
          </div>

          <Link 
            to="/llm_user_mastery"
            className="inline-flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors group"
          >
            <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
            <span>Back to Modules</span>
          </Link>

          <nav className="space-y-1">
          {sections.map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`w-full text-left px-4 py-3 rounded-xl transition-all flex items-center space-x-3
                ${activeSection === section.id 
                  ? 'bg-blue-500/20 text-blue-400 border border-blue-500/50 shadow-lg shadow-blue-500/20' 
                  : section.customStyle || 'hover:bg-slate-800/50 text-slate-300'}`}
            >
              <section.icon size={18} />
              <span>{section.title}</span>
            </button>
          ))}
        </nav>
        </div>
      </div>

      <div className="lg:pl-64">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto">
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl shadow-xl border border-slate-700/30 p-8 mb-8">
              <div className="border-b border-slate-700 pb-6 mb-6">
                <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                  LLM Features Workshop
                </h1>
                <h2 className="text-xl text-blue-400">Practical Applications & Integration</h2>
              </div>

              <div className="space-y-6">
                <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                  {contentSections[activeSection]?.title}
                </h3>
                {contentSections[activeSection]?.content}

               


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Onepsix;