import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { BookOpen, ChevronRight, Clock, Layout } from 'lucide-react';

const Home = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState('');
  const [pageConfig, setPageConfig] = useState({
    big_title: 'AIDATACERT.COM LTD',
    little_title: 'Courses'
  });

  useEffect(() => {
    const loadExcelData = async () => {
      try {
        const response = await fetch('/courses_home_page.xlsx');
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        
        const modulesSheet = workbook.Sheets['modules'];
        const modulesList = XLSX.utils.sheet_to_json(modulesSheet, { 
          header: ['Module Name', 'Status', 'Link'] 
        }).slice(1); // Skip header row
        
        const formattedModules = modulesList
          .filter(module => module['Module Name'])
          .map(module => ({
            title: module['Module Name'],
            status: module['Status']?.toLowerCase() || '',
            description: module['Status']?.toLowerCase() === 'coming soon' 
              ? 'Coming Soon! Stay tuned for updates.'
              : `Click to access: ${module['Module Name']}`,
            link: module['Link'] || '',
            isComingSoon: module['Status']?.toLowerCase() === 'coming soon'
          }));
        
        setCourses(formattedModules);
      } catch (error) {
        console.error('Error loading excel data:', error);
        setError('Failed to load course data');
      }
    };

    loadExcelData();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
      <header className="bg-slate-950/90 backdrop-blur-md border-b border-slate-800/50 sticky top-0 z-50">
        <div className="container mx-auto py-4 px-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-6">
              <img 
                src="/logo.png" 
                alt="AI DATA CERT Logo" 
                className="h-12 w-auto"
              />
              <div className="border-l border-slate-700 pl-6">
                <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-200">
                  {pageConfig.big_title}
                </h1>
              </div>
            </div>
            <div className="flex-1 text-center">
              <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
                {pageConfig.little_title}
              </h2>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-16">
        <div className="flex items-center justify-between mb-12">
          <div>
            <h2 className="text-3xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
              Available Courses
            </h2>
            <p className="text-slate-400">Browse our selection of courses</p>
          </div>
          <div className="flex items-center space-x-3 text-slate-400 bg-slate-800/50 px-4 py-2 rounded-xl border border-slate-700/50">
            <Layout size={20} />
            <span>Showing {courses.length} courses</span>
          </div>
        </div>

        {error && (
          <div className="bg-red-500/10 border border-red-500/20 rounded-xl p-4 mb-8">
            <p className="text-red-400">{error}</p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {courses.map((course, index) => (
            <div 
              key={index}
              className="group bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8 hover:border-blue-500/50 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <div className="flex items-start justify-between mb-6">
                <div className="p-3 bg-blue-500/10 rounded-xl group-hover:bg-blue-500/20 transition-colors">
                  {course.isComingSoon ? (
                    <Clock className="h-6 w-6 text-blue-400" />
                  ) : (
                    <BookOpen className="h-6 w-6 text-blue-400" />
                  )}
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
                {course.title}
              </h3>
              <p className="text-slate-400 text-sm mb-8">
                {course.description}
              </p>
              {course.isComingSoon ? (
                <div className="flex items-center justify-between w-full px-4 py-3 bg-slate-950/50 rounded-xl text-slate-400 cursor-not-allowed">
                  <span className="font-medium">Coming Soon</span>
                  <Clock size={18} />
                </div>
              ) : (
                <Link 
                  to={course.link}
                  className="flex items-center justify-between w-full px-4 py-3 bg-slate-950/50 hover:bg-gradient-to-r hover:from-blue-600 hover:to-blue-500 rounded-xl text-slate-300 hover:text-white transition-all group-hover:shadow-lg group-hover:shadow-blue-500/20"
                >
                  <span className="font-medium">Access Course</span>
                  <ChevronRight size={18} className="group-hover:translate-x-0.5 transition-transform" />
                </Link>
              )}
            </div>
          ))}
        </div>
      </main>

      <footer className="bg-slate-950/90 backdrop-blur-md border-t border-slate-800/50 py-8">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-center space-x-4 text-slate-400">
            <img 
              src="/logo.png" 
              alt="AI DATA CERT Logo" 
              className="h-8 w-auto"
            />
            <span>|</span>
            <p>&copy; {new Date().getFullYear()} {pageConfig.big_title}. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;