import React, { useState, useEffect } from 'react';
import { ArrowLeft, Menu, ArrowRight} from 'lucide-react';
import { Link } from 'react-router-dom';
import Quiz from '../Quiz';
import VideoPlayer from '../VideoPlayer';
import FlipCard from '../FlipCard';
import { 
  BookOpen, 
  Brain, 
  Sparkles, 
  Wrench, 
  AlertTriangle, 
  CheckCircle2, 
  HelpCircle, 
  Book, 
  XCircle,
  FileText,
  User,
  Calendar, 
  BarChart3, 
  Shield, 
  Database,
    Leaf, Share2
} from 'lucide-react';



const Oneptwo = () => {
  const [activeSection, setActiveSection] = useState('introduction');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isQuizComplete, setIsQuizComplete] = useState(false);  // Add this state

  
  useEffect(() => {
    // Immediate scroll to top without smooth behavior on initial load
    window.scrollTo(0, 0);
  }, []); 


  
  // Add a second useEffect to handle section changes
  useEffect(() => {
    // Smooth scroll to top when section changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeSection]);


  const handleSectionChange = (sectionId) => {
    // First change the section
    setActiveSection(sectionId);
    setIsMobileMenuOpen(false);
    
    // Then scroll to top with a slight delay to ensure the new content is rendered
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  };

  const sections = [
    { id: 'introduction', title: 'Introduction', icon: BookOpen },
    { id: 'glossary', title: 'AI Terms Glossary', icon: Book }, // Add this new section
    { id: 'how-they-work', title: 'How do these models work?', icon: Brain },
    { id: 'example', title: 'The Power of Context', icon: Sparkles },
    { id: 'capabilities', title: 'What can LLMs Do?', icon: Wrench },
    { id: 'challenges', title: 'Challenges and Limitations', icon: AlertTriangle },
    { id: 'conclusion', title: 'Summary', icon: CheckCircle2 },
    { id: 'quiz', title: 'Knowledge Check', icon: HelpCircle },
];



  const contentSections = {
    introduction: {
        title: 'Introduction',
        content: (
          <div className="space-y-8">
            <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30 backdrop-blur-xl">
              <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                In this Module ...
              </h3>
              <p className="text-slate-300">
                In this section, we introduce foundational concepts in Artificial Intelligence (AI), covering its evolution, core components, and applications. Starting with the basics of AI and Machine Learning (ML), we examine how these systems emulate human intelligence and learn from data. We then explore Large Language Models (LLMs), a type of AI trained to handle human-like language tasks.
              </p>
            </div>
  
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
              <div className="mb-6">
                <h4 className="text-xl font-semibold text-blue-400 mb-3">
                  Featured Video: Introduction to AI ML and LLMs
                </h4>
                <p className="text-slate-300">
                This video introduces artificial intelligence (AI) and its subsets, highlighting how machine learning and deep learning enable AI to mimic human intelligence. It explores the rapid development of large language models (LLMs) since 2017, driven by advances in algorithms, data availability, and hardware.
                
                 We shall reinforce some of the concepts in the video at later points in this module.
                </p>
              </div>
  
              <VideoPlayer 
                videoUrl="https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/MavenIntroLLMs.mp4"
                title="Deep Dive: Large Language Models in Practice"
              />
            </div>
          </div>
        )
      },



      glossary: {
        title: 'AI Terms Made Simple',
        content: (
          <div className="space-y-8">
            {/* Introduction Section */}
            <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
              <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
                Understanding AI Words
              </h3>
              <p className="text-slate-300">
                Here's a simple guide to the words you'll see throughout this course. We've explained everything in plain English without any complex terms.
              </p>
            </div>
      
            {/* Basic AI Terms */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <h4 className="text-xl font-semibold text-blue-400 mb-6">Core Terms</h4>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <div className="space-y-6">
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Model</p>
                      <p className="text-slate-300">A computer programme designed to handle specific tasks. ChatGPT is a model that's built to understand and respond to text.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Artificial Intelligence (AI)</p>
                      <p className="text-slate-300">Computer systems that can perform tasks which usually need human understanding, such as recognising speech or making decisions.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Large Language Model (LLM)</p>
                      <p className="text-slate-300">A type of AI that can understand and create text. It's built by learning from vast amounts of written material.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Data</p>
                      <p className="text-slate-300">Information used to teach AI systems. This could be text, images, or any other kind of information the AI needs to learn from.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            {/* How AI Works */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <h4 className="text-xl font-semibold text-blue-400 mb-6">How AI Works</h4>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <div className="space-y-6">
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Model Training</p>
                      <p className="text-slate-300">The process of teaching an AI system using large amounts of information. The system learns by looking for patterns in this information.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Model Inference</p>
                      <p className="text-slate-300">When a trained AI system is being used to do its job. This happens when you ask it a question and it gives you an answer.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Algorithm</p>
                      <p className="text-slate-300">A set of steps that a computer follows to complete a task. It's the basic instructions that tell the AI what to do with information.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Compute</p>
                      <p className="text-slate-300">The amount of computer processing power needed to run AI systems. More complex AI systems need more compute power.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            {/* Ways to Use AI */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <h4 className="text-xl font-semibold text-blue-400 mb-6">Ways to Use AI</h4>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <div className="space-y-6">
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Local Model</p>
                      <p className="text-slate-300">An AI system that runs on your own computer instead of over the internet. You need a powerful enough computer to run it.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Cloud-Based Model</p>
                      <p className="text-slate-300">An AI system that runs on remote computers and is accessed through the internet. Most popular AI services work this way.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Local Deployment</p>
                      <p className="text-slate-300">Setting up an AI system to run on your own computer or network. This gives you more control but needs technical knowledge.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">GUI (Graphical User Interface)</p>
                      <p className="text-slate-300">The screen display you use to interact with an AI system. It includes things like text boxes where you type and buttons you click.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            {/* Important Features */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <h4 className="text-xl font-semibold text-blue-400 mb-6">Important Features</h4>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <div className="space-y-6">
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Prompt</p>
                      <p className="text-slate-300">The question or instruction you give to an AI system. Being clear in your prompt helps get better answers.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Context Window</p>
                      <p className="text-slate-300">The amount of text an AI system can consider at once when working on your request.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Multimodal</p>
                      <p className="text-slate-300">The ability of an AI system to work with different types of information at once, such as text and images together.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Hallucination</p>
                      <p className="text-slate-300">When an AI system creates information that seems real but isn't correct. This is why you should check important facts.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Token</p>
                      <p className="text-slate-300">The small pieces of text that AI systems use to process language. A word might be one or more tokens.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            {/* Technical Parts */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
              <h4 className="text-xl font-semibold text-blue-400 mb-6">Technical Parts</h4>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-slate-800/50 rounded-lg p-6">
                  <div className="space-y-6">
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">API</p>
                      <p className="text-slate-300">A way for different computer programmes to connect with AI systems. It lets websites and apps use AI features.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">GPU (Graphics Card)</p>
                      <p className="text-slate-300">A special type of computer part that helps run AI systems quickly. They're particularly good at handling AI tasks.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Server</p>
                      <p className="text-slate-300">A powerful computer that runs AI systems and responds to requests from users over the internet.</p>
                    </div>
      
                    <div className="border-b border-slate-700/50 pb-4">
                      <p className="text-blue-400 font-medium">Fine-tuning</p>
                      <p className="text-slate-300">Adjusting an existing AI system to work better for specific tasks or topics.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
          </div>
        )
      },

  
'how-they-work': {
  title: 'How do these models work?',
  content: (
    <div className="space-y-8">
      <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
        <p className="text-slate-300">
          LLMs are built using three essential components: data, computing power, and a powerful algorithm. The combination of these three elements explains why LLMs have made such a significant impact, particularly since 2022.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <FlipCard 
          frontTitle="Data"
          frontIcon={
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4" />
            </svg>
          }
          frontContent={
            <div className="space-y-4">
            <p>"Over the past two decades, we have been generating and uploading vast amounts of data online. This includes everything from social media posts and articles to entire websites." </p>
         
            <div className="mt-4 flex items-center justify-center">
            <div className="group cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400">
              <span className="animate-pulse">Click for more</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 animate-bounce" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            </div>
            </div>
           
         
          }
          backTitle="Data in Action: Wikipedia Analysis"
          backContent={
            <div className="space-y-4">
              <p>Case Study: Wikipedia as a source of Training Data</p>
              <ul className="list-disc list-inside space-y-2">
                <li>6+ million English articles</li>
                <li>3.9 billion words</li>
                <li>Structured knowledge</li>
                <li>Regular updates</li>
              </ul>
              <p className="text-sm italic">This demonstrates how a single source can provide vast amounts of high-quality training data.</p>
            </div>
          }
        />

        <FlipCard 
          frontTitle="Compute Power"
          frontIcon={
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
            </svg>
          }

          frontContent={
            <div className="space-y-4">
            <p>"Alongside data growth, computing technology has seen significant advances, particularly in Graphics Processing Units (GPUs), revolutionising how quickly these models can be trained."</p>
         
            <div className="mt-4 flex items-center justify-center">
            <div className="group cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400">
              <span className="animate-pulse">Click for more</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 animate-bounce" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            </div>
            </div>
           
         
          }

         
         
         
          backTitle="GPU Computing Example"
          backContent={
            <div className="space-y-4">
              <p>Modern AI Training Setup:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Model training uses 1000s of GPUs</li>
                <li>New facilities (data centers) devloped specificaly for LLM training</li>
                <li>Advanced cooling infrastructure</li>
              </ul>
              <p className="text-sm italic">This setup can process millions of text examples per second.</p>
            </div>
          }
        />

        <FlipCard 
          frontTitle="Algorithms"
          frontIcon={
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
            </svg>
          }
          
          frontContent={
            <div className="space-y-4">
            <p>"A new type of algorithm design, the transformer, has enabled LLMs to process and generate text more effectively than ever before."
            </p>
         
            <div className="mt-4 flex items-center justify-center">
            <div className="group cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400">
              <span className="animate-pulse">Click for more</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 animate-bounce" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            </div>
            </div>
           
         
          }


          backTitle="Transformer Architecture"
          backContent={
            <div className="space-y-4">
              <p>Key Transformer Features:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Places more attention on some words over others</li>
                <li>Understands text within the full context</li>
                <li>Scalable to work with bigger models</li>
              </ul>
              <p className="text-sm italic">This architecture allows models to understand context and relationships in text better than ever before.</p>
            </div>
          }
        />
      </div>

      <div className="rounded-xl overflow-hidden border border-gray-700 shadow-lg">
        <img 
          src="https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
          alt="AI Technology Illustration"
          className="w-full h-auto object-cover"
        />
      </div>
    </div>
  )
},
  
      example: {
        title: 'The Power of Context',
        content: (
            <div className="space-y-8">
              {/* Introduction Card */}
              <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
                <h3 className="text-xl font-semibold text-blue-400 mb-4">Understanding Context in LLMs</h3>
                <p className="text-slate-300">
                  Let's explore how LLMs use context to make predictions through examples that demonstrate how additional information helps the model generate more accurate and relevant responses. Furthermore this process can help us understand how these algorithms learnt to understand language in the first place.
                </p>
              </div>
    
              {/* First Example - Barry the Dog */}
              <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
                <h4 className="text-xl font-semibold text-blue-400 mb-4">Example 1: Barry the Dog</h4>
                <p className="text-slate-300">
                 Remember that a LLM is looking to generate text with the most likely response given the words that came before. 
                </p>
                <br />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Limited Context */}
                  <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                    <h5 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                      Limited Context
                    </h5>
                    <div className="space-y-4">
                      <div className="p-4 bg-slate-900/50 rounded-xl border border-slate-800/50">
                        <p className="text-slate-300 font-mono">"Barry the dog was ..."</p>
                      </div>
                      <div className="space-y-3">
                        <p className="text-slate-300">Likely predictions:</p>
                        <div className="space-y-2">
                          {[
                            { label: "running", percent: 80 },
                            { label: "barking", percent: 60 },
                            { label: "hungry", percent: 40 }
                          ].map((item, index) => (
                            <div key={index} className="flex items-center">
                              <div className="w-24 bg-blue-500/20 rounded-full h-4 mr-3">
                                <div 
                                  className="bg-blue-500 h-full rounded-full transition-all duration-300"
                                  style={{ width: `${item.percent}%` }}
                                />
                              </div>
                              <span className="text-blue-400">{item.label} ({item.percent}%)</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      
                    </div>
                    
                  </div>
    
                  {/* Rich Context */}
                  <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                    <h5 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                      Rich Context
                    </h5>
                    <div className="space-y-4">
                      <div className="p-4 bg-slate-900/50 rounded-xl border border-slate-800/50">
                        <p className="text-slate-300 font-mono">
                          "Barry the dog had just finished following his owner's scent for miles upon miles and was ..."
                        </p>
                      </div>
                      <div className="space-y-3">
                        <p className="text-slate-300">Refined predictions:</p>
                        <div className="space-y-2">
                          {[
                            { label: "exhausted", percent: 90 },
                            { label: "tired", percent: 80 },
                            { label: "hungry", percent: 60 }
                          ].map((item, index) => (
                            <div key={index} className="flex items-center">
                              <div className="w-24 bg-green-500/20 rounded-full h-4 mr-3">
                                <div 
                                  className="bg-green-500 h-full rounded-full transition-all duration-300"
                                  style={{ width: `${item.percent}%` }}
                                />
                              </div>
                              <span className="text-green-400">{item.label} ({item.percent}%)</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <br />
                <p className="text-slate-300">
                 In this example the addition of the knowledge of Barry's activity allowed us to make a prediction that would be more likely to fit to that situation. During training the LLM is constantly evaluating billions of text words and sentences in order to refine its ability to predicit the next word. And to that effectively the model must learn to understand language context. Let's look at another example.
                </p>
              </div>

                          {/* Illustration */}
            <div className="rounded-xl overflow-hidden border border-gray-700 shadow-lg">
              <img 
                src="https://images.pexels.com/photos/2253275/pexels-photo-2253275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
                alt="Tired dog resting after activity"
                className="w-full h-64 object-cover"
              />
            </div>
    
              {/* Second Example - The Restaurant */}
              <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
                <h4 className="text-xl font-semibold text-blue-400 mb-4">Example 2: The Restaurant</h4>
          
                <p className="text-slate-300">
                 As you go through this next example consider how the extra context will allow the model to generate more appropriate responses for the situation.
                </p>
                <br />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Limited Context */}
                  <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                    <h5 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                      Limited Context
                    </h5>
                    <div className="space-y-4">
                      <div className="p-4 bg-slate-900/50 rounded-xl border border-slate-800/50">
                        <p className="text-slate-300 font-mono">"The waiter brought the ..."</p>
                      </div>
                      <div className="space-y-3">
                        <p className="text-slate-300">Likely predictions:</p>
                        <div className="space-y-2">
                          {[
                            { label: "food", percent: 80 },
                            { label: "menu", percent: 60 },
                            { label: "bill", percent: 40 }
                          ].map((item, index) => (
                            <div key={index} className="flex items-center">
                              <div className="w-24 bg-blue-500/20 rounded-full h-4 mr-3">
                                <div 
                                  className="bg-blue-500 h-full rounded-full transition-all duration-300"
                                  style={{ width: `${item.percent}%` }}
                                />
                              </div>
                              <span className="text-blue-400">{item.label} ({item.percent}%)</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
    
                  {/* Rich Context */}
                  <div className="bg-slate-950/50 rounded-xl p-6 border border-slate-700/50">
                    <h5 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                      Rich Context
                    </h5>
                    <div className="space-y-4">
                      <div className="p-4 bg-slate-900/50 rounded-xl border border-slate-800/50">
                        <p className="text-slate-300 font-mono">
                          "After finishing their delicious main course at the expensive French restaurant, the couple was ready to see the dessert options. The waiter brought the ..."
                        </p>
                      </div>
                      <div className="space-y-3">
                        <p className="text-slate-300">Refined predictions:</p>
                        <div className="space-y-2">
                        {[
                        { label: "dessert menu", percent: 90 },
                        { label: "wine list", percent: 40 },
                        { label: "bill", percent: 20 }
                      ].map((item, index) => (
                        <div key={index} className="flex items-center">
                          <div className="w-24 bg-green-500/20 rounded-full h-4 mr-3">
                            <div 
                              className="bg-green-500 h-full rounded-full transition-all duration-300"
                              style={{ width: `${item.percent}%` }}
                            />
                          </div>
                          <span className="text-green-400">{item.label} ({item.percent}%)</span>
                        </div>
                      ))}
                    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
            <br />
            <p className="text-slate-300">
                In this example note how the additional context narrowed down the range of suitable next words. Our human understanding of context imporved our prediction of the next word. LLMs are having to learn to understand the meaning of language in order to achieve their training task which is to predict the next word.
                </p>
               
          </div>

          {/* Understanding Section */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
            <h4 className="text-xl font-semibold text-blue-400 mb-3">Understanding Word Predictions</h4>
            <p className="text-slate-300 mb-4">
              In both examples, we can see how the model assigns different probabilities to potential next words based on the available context. The percentages represent the model's confidence in each prediction, which is directly influenced by the amount and relevance of context provided.
            </p>
            <div className="bg-blue-500/10 rounded-xl p-4 border border-blue-500/20">
              <h5 className="text-lg font-semibold text-blue-400 mb-2">How Context Shapes Probabilities:</h5>
              <ul className="list-disc list-inside text-slate-300 space-y-2">
                <li>With limited context, predictions are more generalised and spread across common possibilities</li>
                <li>Additional context narrows down the possibilities and increases confidence in specific predictions</li>
                <li>New, more specific predictions may emerge with richer context</li>
              </ul>
            </div>
          </div>
        </div>
      )
    },


'capabilities': {
  title: 'What can LLMs Do?',
  content: (
    <div className="space-y-8">
      {/* Introduction Card */}
      <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
        <h3 className="text-xl font-semibold text-blue-400 mb-4">Understanding LLM Capabilities</h3>
        <p className="text-slate-300">
          Large Language Models have changed how we interact with artificial intelligence. From their foundational text-processing abilities to advanced multimodal capabilities, these models offer increasingly sophisticated ways to understand and generate content.
        </p>
        <br/>
        <p className="text-slate-300">
          Below we explore some of the common use cases of text only LLMs.
        </p>
      </div>

      {/* Capabilities Grid with Flip Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <FlipCard 
  frontTitle="Translation"
  frontIcon={
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
    </svg>
  }
  frontContent={
    <>
    <div className="h-48 mb-4 rounded-xl overflow-hidden">
      <img 
        src="https://images.pexels.com/photos/346885/pexels-photo-346885.jpeg" 
        alt="Language Translation"
        className="w-full h-full object-cover"
      />

    </div>

    <div className="mt-4 flex items-center justify-center">
          <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
            <span>click to explore</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </>


    
  }


  backTitle="What can it translate?"
  backContent={
    <div className="space-y-4">
      <p>This AI can translate text from one language to another while keeping the original meaning and tone. It's like having a skilled translator who understands both languages really well.</p>
      <div className="bg-slate-800/50 p-4 rounded-lg">
        <p className="font-semibold mb-2">For example:</p>
        <ul className="space-y-2">
          <li>• Translates business documents while keeping professional language</li>
          <li>• Helps translate websites for different countries</li>
          <li>• Understands casual conversation and slang</li>
          <li>• Can match the style of writing when translating</li>
        </ul>
      </div>
    </div>
    
  }
/>

<FlipCard 
  frontTitle="Text Analysis"
  frontIcon={
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
    </svg>
  }
  frontContent={
    <>
    <div className="h-48 mb-4 rounded-xl overflow-hidden">
      <img 
        src="https://images.pexels.com/photos/261857/pexels-photo-261857.jpeg" 
        alt="Text Analysis"
        className="w-full h-full object-cover"
      />
    </div>

    <div className="mt-4 flex items-center justify-center">
          <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
            <span>click to explore</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </>
  }
  backTitle="How does it analyse text?"
  backContent={
    <div className="space-y-4">
      <p>This AI can read and understand text to spot patterns and meanings. It's like having a very thorough reader who can quickly understand large amounts of text.</p>
      <div className="bg-slate-800/50 p-4 rounded-lg">
        <p className="font-semibold mb-2">For example:</p>
        <ul className="space-y-2">
          <li>• Figures out if feedback is positive or negative</li>
          <li>• Spots important topics in documents</li>
          <li>• Finds names and organisations in text</li>
          <li>• Groups similar ideas together</li>
        </ul>
      </div>
    </div>
  }
/>

<FlipCard 
  frontTitle="Summarisation"
  frontIcon={
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h7" />
    </svg>
  }
  frontContent={
    <>
    <div className="h-48 mb-4 rounded-xl overflow-hidden">
      <img 
        src="https://images.pexels.com/photos/7054415/pexels-photo-7054415.jpeg" 
        alt="Text Summarisation"
        className="w-full h-full object-cover"
      />
    </div>

    <div className="mt-4 flex items-center justify-center">
          <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
            <span>click to explore</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </>


  }
  backTitle="How does it summarise?"
  backContent={
    <div className="space-y-4">
      <p>This AI can take long pieces of text and make them shorter while keeping the important points. It's like having someone read a long document and tell you the key things you need to know.</p>
      <div className="bg-slate-800/50 p-4 rounded-lg">
        <p className="font-semibold mb-2">For example:</p>
        <ul className="space-y-2">
          <li>• Creates short versions of long reports</li>
          <li>• Pulls out the main points from meetings</li>
          <li>• Makes quick summaries of news articles</li>
          <li>• Highlights the most important information</li>
        </ul>
      </div>
    </div>
  }

        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
  <FlipCard 
    frontTitle="Creative Writing"
    frontIcon={
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
      </svg>
    }
    frontContent={
      <>
      <div className="h-48 mb-4 rounded-xl overflow-hidden">
        <img 
          src="https://images.pexels.com/photos/256514/pexels-photo-256514.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Creative Writing"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="mt-4 flex items-center justify-center">
          <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
            <span>click to explore</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </>


    }
    backTitle="What can it write?"
    backContent={
      <div className="space-y-4">
        <p>This AI can create original written content across various styles and formats. It's like having a versatile writer who can adapt to different creative tasks.</p>
        <div className="bg-slate-800/50 p-4 rounded-lg">
          <p className="font-semibold mb-2">For example:</p>
          <ul className="space-y-2">
            <li>• Crafts engaging short stories and poetry</li>
            <li>• Creates professional marketing copy</li>
            <li>• Writes blog posts and articles</li>
            <li>• Develops scripts and dialogue</li>
          </ul>
        </div>
      </div>
    }
  />

  <FlipCard 
    frontTitle="Code Generation"
    frontIcon={
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
      </svg>
    }
    frontContent={
      <>
      <div className="h-48 mb-4 rounded-xl overflow-hidden">
        <img 
          src="https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Code Generation"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="mt-4 flex items-center justify-center">
          <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
            <span>click to explore</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </>
    }
    backTitle="How does it code?"
    backContent={
      <div className="space-y-4">
        <p>This AI can write, explain, and debug code across multiple programming languages. It's like having an experienced programmer who can help with various development tasks.</p>
        <div className="bg-slate-800/50 p-4 rounded-lg">
          <p className="font-semibold mb-2">For example:</p>
          <ul className="space-y-2">
            <li>• Generates functional code solutions</li>
            <li>• Converts between programming languages</li>
            <li>• Helps debug and optimise code</li>
            <li>• Creates coding documentation</li>
          </ul>
        </div>
      </div>
    }
  />

  <FlipCard 
    frontTitle="Content Expansion"
    frontIcon={
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
      </svg>
    }
    frontContent={
      <>
      <div className="h-48 mb-4 rounded-xl overflow-hidden">
        <img 
          src="https://images.pexels.com/photos/159866/books-book-pages-read-literature-159866.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Content Expansion"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="mt-4 flex items-center justify-center">
          <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
            <span>click to explore</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </>

      
    }
    backTitle="How does it expand content?"
    backContent={
      <div className="space-y-4">
        <p>This AI can take brief inputs and develop them into comprehensive content. It's like having an expert who can flesh out ideas into detailed, well-structured pieces.</p>
        <div className="bg-slate-800/50 p-4 rounded-lg">
          <p className="font-semibold mb-2">For example:</p>
          <ul className="space-y-2">
            <li>• Transforms bullet points into full paragraphs</li>
            <li>• Develops outlines into detailed articles</li>
            <li>• Expands brief notes into proposals</li>
            <li>• Creates detailed specifications from concepts</li>
          </ul>
        </div>
      </div>
    }
  />
</div>
    
          {/* Conversational Capabilities */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-8 border border-slate-700/30">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Conversational Capabilities</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-4">
                <div className="bg-slate-950/50 rounded-xl p-4 border border-slate-700/50">
                  <h5 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-2">
                    Context Management
                  </h5>
                  <p className="text-slate-300">
                    LLMs maintain context throughout conversations, enabling natural dialogue flow. They can use infomation discussed at any point in the history of the conversation to imporve the current response.
                  </p>
                </div>
                <div className="bg-slate-950/50 rounded-xl p-4 border border-slate-700/50">
                  <h5 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-2">
                    Context Size 
                  </h5>
                  <p className="text-slate-300">
                    LLMs have the ability to hold many hundreds of thousands, and in the case of some models millions of words within their context that can be built up through a conversation or provided in an initial prompt (message). This enables the models to provide extremely detailed responses based on all the infomation provided in the context. The number of words or tokens (1 token represents around 0.75 words) is known as a context window.
                  </p>
                </div>
              </div>
              
              <div className="bg-slate-950/50 rounded-xl overflow-hidden">
                <img 
                  src="https://images.pexels.com/photos/17483869/pexels-photo-17483869.jpeg" 
                  alt="AI Conversation"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
    
          {/* Applications Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
              <h4 className="text-lg font-semibold text-blue-400 mb-4">Business Applications</h4>
              <ul className="space-y-3 text-slate-300">
                {[
                  "Customer service automation and support",
                  "Document analysis and contract review",
                  "Market research and sentiment analysis",
                  "Content creation and copywriting",
                  "Email drafting and communication assistance"
                ].map((item, index) => (
                  <li key={index} className="flex items-start space-x-2">
                    <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                      <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                    </div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-6 border border-slate-700/30">
              <h4 className="text-lg font-semibold text-blue-400 mb-4">Educational Applications</h4>
              <ul className="space-y-3 text-slate-300">
                {[
                  "Personalised tutoring and explanations",
                  "Research assistance ",
                  "Language learning and practice",
                  "Essay feedback and improvement",
                  "Study material generation"
                ].map((item, index) => (
                  <li key={index} className="flex items-start space-x-2">
                    <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                      <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                    </div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

                      {/* Multimodal Evolution Section */}
                      <div>
              <div className="bg-gradient-to-r from-blue-900/20 to-purple-900/20 rounded-lg p-8 border border-blue-500/20 mb-8">
                <h3 className="text-2xl font-semibold text-white mb-6">Evolution to Multimodal Capabilities</h3>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <p className="text-gray-300 mb-6">
                      Modern LLMs have evolved beyond text processing, incorporating the ability to understand and generate multiple types of content. This evolution has dramatically expanded their practical applications and utility.
                    </p>
                    <div className="bg-gray-900/50 rounded-lg p-4 border border-gray-600">
                      <h5 className="text-lg font-semibold text-white mb-2">Key Advances</h5>
                      <ul className="space-y-2 text-gray-300">
                        <li>• Integration of image processing</li>
                        <li>• Audio understanding and generation</li>
                        <li>• Video understanding and generation</li>
                        <li>• Cross-modal learning capabilities and understanding (e.g infomation from images and text can be combined)</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div className="bg-gray-900/50 rounded-lg overflow-hidden">
                    <div className="relative h-full min-h-[250px]">
                      <img src="/logo.png" alt="AI DATA CERT Logo" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-16 opacity-20" />
                      <div className="text-center p-4 text-gray-400">
                      <div className="bg-gray-900/50 rounded-lg overflow-hidden">
  <div className="relative h-[300px]"> {/* Fixed height container */}
    <img 
                          src="https://media.istockphoto.com/id/1144557228/photo/telecommunication-network-above-city-wireless-mobile-internet-technology-for-smart-grid-or-5g.jpg?s=2048x2048&w=is&k=20&c=dkKVu84DY7a0Fn4CK7Zv9GKRrzVgFhFm3iT2BOlmE2w=" 
                          alt="Descriptive alt text"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
    
              {/* Advanced Multimodal Applications */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Visual Processing */}
                <div className="bg-gray-800/30 rounded-lg p-6 border border-gray-700">
                  <div className="h-48 mb-4 rounded-lg overflow-hidden bg-gray-900/50">
                    <div className="relative h-full">
                      <img src="/logo.png" alt="AI DATA CERT Logo" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-16 opacity-20" />
                      <div className="text-center p-4 text-gray-400">
                      <div className="bg-gray-900/50 rounded-lg overflow-hidden">
  <div className="relative h-[300px]"> {/* Fixed height container */}
    <img 
                          src="https://visionplatform.ai/wp-content/uploads/2024/01/object-detection.png" 
                          alt="Descriptive alt text"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="text-lg font-semibold text-blue-400 mb-2">Visual Processing</h4>
                  <ul className="space-y-2 text-gray-300">
                    <li>• Image generation from descriptions</li>
                    <li>• Visual content analysis</li>
                    <li>• Automated image captioning</li>
                    <li>• Optical Character Recognition (OCR) - extracting text out of images</li>
                  </ul>
                </div>
    
                {/* Audio Capabilities */}
                <div className="bg-gray-800/30 rounded-lg p-6 border border-gray-700">
                  <div className="h-48 mb-4 rounded-lg overflow-hidden bg-gray-900/50">
                    <div className="relative h-full">
                      <img src="/logo.png" alt="AI DATA CERT Logo" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-16 opacity-20" />
                      <div className="text-center p-4 text-gray-400">
                      <div className="bg-gray-900/50 rounded-lg overflow-hidden">
  <div className="relative h-[300px]"> {/* Fixed height container */}
    <img 
                          src="https://images.pexels.com/photos/164829/pexels-photo-164829.jpeg?auto=compress&cs=tinysrgb&w=600" 
                          alt="Descriptive alt text"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      </div>

                        
                      </div>
                    </div>
                  </div>
                  <h4 className="text-lg font-semibold text-blue-400 mb-2">Audio Processing</h4>
                  <ul className="space-y-2 text-gray-300">
                    <li>• Speech recognition</li>
                    <li>• Voice synthesis</li>
                    <li>• Audio content analysis</li>
                    <li>• Music generation</li>
                  </ul>
                </div>
    
                {/* Combined Applications */}
                <div className="bg-gray-800/30 rounded-lg p-6 border border-gray-700">
                  <div className="h-48 mb-4 rounded-lg overflow-hidden bg-gray-900/50">
                    <div className="relative h-full">
                      <img src="/logo.png" alt="AI DATA CERT Logo" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-16 opacity-20" />
                      <div className="text-center p-4 text-gray-400">
                      <div className="bg-gray-900/50 rounded-lg overflow-hidden">
  <div className="relative h-[300px]"> {/* Fixed height container */}
    <img 
                          src="https://media.istockphoto.com/id/123388042/photo/film-projector-with-blank-frame.jpg?s=612x612&w=0&k=20&c=p3JMv_KLISww2JdXfRpsSAgKtMg1yIRb7W3fQ8PIn0U=" 
                          alt="Descriptive alt text"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      </div>

                        h
                      </div>
                    </div>
                  </div>
                  <h4 className="text-lg font-semibold text-blue-400 mb-2">Text to Video</h4>
                  <ul className="space-y-2 text-gray-300">
                    <li>• Generate video from text</li>
                    <li>• Generate text from video</li>
                    <li>• Video summarisation</li>
          
                  </ul>
                </div>
              </div>
            </div>
                    {/* Case Study - Integrated Capabilities */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-8 border border-slate-700/30 mb-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Case Study - Integrated Capabilities</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <p className="text-slate-300 mb-6">
                  An integrated multi-modal LLM could revolutionise healthcare. By processing text, images, and voice data simultaneously, it can analyse symptoms, interpret medical scans, and understand patient queries. The model combines information from each modality to provide more comprehensive and accurate diagnoses. This technology promises personalised care, remote consultations, and advancements in medical research, ultimately improving patient outcomes.
                </p>
              </div>
              <div className="bg-slate-950/50 rounded-xl overflow-hidden">
                <img 
                  src="https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="Healthcare AI Integration"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          {/* LLMs Integrating with Existing Applications */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl p-8 border border-slate-700/30">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">LLMs Integrating with Existing Applications</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <p className="text-slate-300 mb-6">
                  LLMs can be integrated with existing applications to enhance user experiences. For example, Google integrates their LLM Gemini into its search engine to provide more comprehensive and informative results, understanding complex queries and generating concise summaries. Additionally, Microsoft integrates an LLM as part of MS Copilot into Outlook to automate tasks such as drafting emails and summarising lengthy communications.
                </p>
              </div>
              <div className="bg-slate-950/50 rounded-xl overflow-hidden">
                <img 
                  src="https://media.istockphoto.com/id/1586590970/photo/chatgpt-bing-and-google.jpg?s=612x612&w=0&k=20&c=Nn2k7pyRYFPk5dAY-o6cRvOvuUB7oOqCLuDpRM_esAU=" 
                  alt="LLM Application Integration"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>

        
      )





      
    },




    challenges: {
      title: 'Challenges and Limitations',
      content: (
        <div className="space-y-12">
          {/* Introduction Section */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-3xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Understanding the Challenges of Large Language Models
            </h3>
            
            <p className="text-slate-300 text-lg mb-8 max-w-3xl">
              While these AI systems are impressive, they face several important challenges that need to be understood and addressed. Let's explore each of these challenges in detail.
            </p>
          </div>
    
          {/* Computing Challenge Card */}
          <FlipCard 
            frontTitle="The Computing Challenge"
            frontIcon={<BarChart3 className="w-8 h-8 text-blue-400" />}

            
            frontContent={
              <div className="space-y-4">
                <div className="h-48 rounded-xl overflow-hidden">
                  <img 
                    src="https://media.istockphoto.com/id/1903219030/photo/data-center-in-server-room-with-server-racks.jpg?s=612x612&w=0&k=20&c=La4Xd77cREJJ6dYd8dNl2kbBTVJuiU3Q7O6eEKM5kN4=" 
                    alt="Data Centre"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="prose prose-invert max-w-none">
                  <p className="text-slate-300">
                    Training these AI models requires enormous computing power - imagine trying to power a small city. The cost and energy needed are massive, making it a challenge to build and run these systems.
                  </p>
                </div>

                <div className="mt-4 flex items-center justify-center">
                <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>


              </div>
            }
            backTitle="Computing Power in Detail"
            backContent={
              <div className="space-y-6">
                <p className="text-slate-300">
                  Training modern AI models requires thousands of high-end computers working together non-stop for months. To put this in perspective, training a large model like GPT-4 was estimated to cost upwards of £50 million and uses enough energy to power several thousand homes for a year.
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-slate-800/50 rounded-lg p-4">
                    <h5 className="font-semibold text-blue-300 mb-2">Hardware Costs</h5>
                    <p className="text-2xl font-bold text-white">£50M+</p>
                    <p className="text-sm text-slate-400 mt-2">This includes special AI computers, storage systems, and networking - like building a small data centre.</p>
                  </div>
                  <div className="bg-slate-800/50 rounded-lg p-4">
                    <h5 className="font-semibold text-blue-300 mb-2">Power Usage</h5>
                    <p className="text-2xl font-bold text-white">62,000,000+</p>
                    <p className="text-sm text-slate-400 mt-2">Kilowatt-hours used in training - about what 22,000 UK homes use in a year.</p>
                  </div>
                </div>
              </div>
            }
          />
    
          {/* Data Quality Card */}
          <FlipCard 
            frontTitle="The Data Challenge"
            frontIcon={<Database className="w-8 h-8 text-purple-400" />}
            frontContent={
              <div className="space-y-4">
                <div className="h-48 rounded-xl overflow-hidden">
                  <img 
                    src="https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
                    alt="Data Visualisation"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="prose prose-invert max-w-none">
                  <p className="text-slate-300">
                    Just like a student needs good textbooks to learn, AI models need high-quality data to train on. Getting the right data, ensuring it's accurate, and using it legally are all major challenges.
                  </p>
                </div>

                <div className="mt-4 flex items-center justify-center">
                <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>


              </div>
            }
            backTitle="Data Quality in Detail"
            backContent={
              <div className="space-y-6">
                <p className="text-slate-300">
                  AI models learn from processing vast amounts of text data. The quality and variety of this training data directly affects how well the AI will perform. It's like teaching a student - the better the learning materials, the better the outcome.
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-slate-800/50 rounded-lg p-4">
                    <h5 className="text-lg font-semibold text-purple-300 mb-3">Main Concerns</h5>
                    <ul className="space-y-3 text-slate-300">
                      <li>• Data may contain harmful biases</li>
                      <li>• Need to respect copyright laws</li>
                      <li>• Must support many languages</li>
                      <li>• Data quality varies widely</li>
                    </ul>
                  </div>
                  <div className="bg-slate-800/50 rounded-lg p-4">
                    <h5 className="text-lg font-semibold text-purple-300 mb-3">Current Solutions</h5>
                    <ul className="space-y-3 text-slate-300">
                      <li>• Better filtering systems</li>
                      <li>• Fair use agreements</li>
                      <li>• Quality checking tools</li>
                      <li>• Diverse data sources</li>
                    </ul>
                  </div>
                </div>
    
                <div className="bg-slate-800/50 rounded-lg p-4">
                  <p className="text-3xl font-bold text-white mb-1">1000TB+</p>
                  <p className="text-sm text-slate-400">Average training data size - like reading 1.75 million books</p>
                </div>
              </div>
            }
          />
    
          {/* Privacy Card */}
          <FlipCard 
            frontTitle="The Privacy Challenge"
            frontIcon={<Shield className="w-8 h-8 text-red-400" />}
            frontContent={
              <div className="space-y-4">
                <div className="h-48 rounded-xl overflow-hidden">
                  <img 
                    src="https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
                    alt="Privacy and Security"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="prose prose-invert max-w-none">
                  <p className="text-slate-300">
                    As AI systems handle more personal information, keeping that data safe becomes crucial. It's like having a very knowledgeable assistant who must be trusted with sensitive information.
                  </p>
                </div>

                <div className="mt-4 flex items-center justify-center">
                <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>


              </div>
            }
            backTitle="Privacy & Security in Detail"
            backContent={
              <div className="space-y-6">
                <p className="text-slate-300">
                  When AI systems process lots of information, they often encounter private data. Protecting this information while still allowing the AI to learn is a delicate balance that requires careful management and strong security measures.
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-slate-800/50 rounded-lg p-4">
                    <h5 className="font-semibold text-red-300 mb-3">Protection Methods</h5>
                    <ul className="space-y-3 text-slate-300">
                      <li>• Secure data storage</li>
                      <li>• Detecting and obscuration of personal data</li>

                    </ul>
                  </div>
                  <div className="bg-slate-800/50 rounded-lg p-4">
                    <h5 className="font-semibold text-red-300 mb-3">Legal Requirements</h5>
                    <ul className="space-y-3 text-slate-300">
                      <li>• Data protection laws</li>
                      <li>• User permissions</li>
                      <li>• Regional compliance</li>
                      <li>• Regular audits</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          />
    
          {/* Ethics Card */}
          <FlipCard 
            frontTitle="The Ethics Challenge"
            frontIcon={<Brain className="w-8 h-8 text-yellow-400" />}
            frontContent={
              <div className="space-y-4">
                <div className="h-48 rounded-xl overflow-hidden">
                  <img 
                    src="https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
                    alt="AI Ethics"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="prose prose-invert max-w-none">
                  <p className="text-slate-300">
                    Making sure AI systems are fair and beneficial to everyone is a key challenge. We need to ensure these powerful tools help society without causing unintended harm.
                  </p>
                </div>

                <div className="mt-4 flex items-center justify-center">
                <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>


              </div>
            }
            backTitle="Ethical Considerations in Detail"
            backContent={
              <div className="space-y-6">
                <p className="text-slate-300">
                  As AI systems become more powerful, ensuring they're developed and used responsibly becomes increasingly important. This isn't just about preventing harm - it's about actively working to make AI beneficial for everyone.
                </p>
                
                <div className="bg-slate-800/50 rounded-lg p-4">
                  <h5 className="font-semibold text-yellow-300 mb-4">Key Concerns</h5>
                  <div className="grid grid-cols-1 gap-4 text-slate-300">
                    <div className="space-y-2">
                      <div className="font-medium">Fairness</div>
                      <p className="text-sm text-slate-400">Making sure AI treats everyone equally and doesn't discriminate</p>
                    </div>
                    <div className="space-y-2">
                      <div className="font-medium">Transparency</div>
                      <p className="text-sm text-slate-400">Understanding how AI makes decisions, especially for important choices</p>
                    </div>
                    <div className="space-y-2">
                      <div className="font-medium">Responsibility</div>
                      <p className="text-sm text-slate-400">Knowing who's in charge when AI makes mistakes</p>
                    </div>
                    <div className="space-y-2">
                      <div className="font-medium">Job Impact</div>
                      <p className="text-sm text-slate-400">Planning for how AI might change the way we work</p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
    
          {/* Environmental Card */}
          <FlipCard 
            frontTitle="The Environmental Challenge"
            frontIcon={<Leaf className="w-8 h-8 text-green-400" />}
            frontContent={
              <div className="space-y-4">
                <div className="h-48 rounded-xl overflow-hidden">
                  <img 
                    src="https://images.pexels.com/photos/1108572/pexels-photo-1108572.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
                    alt="Environmental Impact"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="prose prose-invert max-w-none">
                  <p className="text-slate-300">
                    Training AI models uses a lot of energy, which impacts our environment. Finding ways to make AI more environmentally friendly is becoming increasingly important.
                  </p>
                </div>

                <div className="mt-4 flex items-center justify-center">
                <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>


              </div>
            }
            backTitle="Environmental Impact in Detail"
            backContent={
              <div className="space-y-6">
                <p className="text-slate-300">
                  The environmental cost of training AI models is significant. Each large model can produce as much carbon as hundreds of cars driving for a year. Finding sustainable ways to power these systems is crucial.
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="bg-slate-800/50 rounded-lg p-6">
                    <h5 className="font-semibold text-green-300 mb-4">Carbon Impact</h5>
                    <p className="text-3xl font-bold text-white mb-2">7000</p>
                    <p className="text-sm text-slate-400">Tonnes of CO2 per large model</p>
                    <ul className="mt-4 space-y-2 text-sm text-slate-400">
                      <li>• Like 1500 cars running for a year</li>
                     
                    </ul>
                  </div>
                  
                  <div className="bg-slate-800/50 rounded-lg p-6">
                    <h5 className="font-semibold text-green-300 mb-4">Current Solutions</h5>
                    <ul className="space-y-3 text-slate-300">
                      <li>• Using renewable energy to power training and predictions.</li>
                      <li>• Better cooling systems</li>
                      <li>• Carbon offsetting</li>
                      <li>• More efficient training</li>
                    </ul>
                  </div>
    
                  <div className="bg-slate-800/50 rounded-lg p-6">
                    <h5 className="font-semibold text-green-300 mb-4">Future Goals</h5>
                    <ul className="space-y-3 text-slate-300">
                      <li>• Smarter training methods</li>
                      <li>• Better hardware use</li>
                      <li>• Improved efficiency</li>
                      <li>• Sustainable practices</li>
                    </ul>
                  </div>
                </div>
              </div>
            }

            />       
          <FlipCard 
          frontTitle="Case Study: AI in Social Media"
          frontIcon={<Share2 className="w-8 h-8 text-indigo-400" />}
          frontContent={
            <div className="space-y-4">
              <div className="h-48 rounded-xl overflow-hidden">
                <img 
                  src="https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg" 
                  alt="Social Media"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="prose prose-invert max-w-none">
                <p className="text-slate-300">
                  Imagine a popular social media platform using AI to moderate content, recommend posts, and detect harmful material. Let's see how this everyday example faces all the challenges we've discussed.
                </p>
              </div>

              <div className="mt-4 flex items-center justify-center">
                <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>

              
            </div>
          }
          backTitle="Real-World Challenges in Social Media AI"
          backContent={
            <div className="space-y-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-indigo-300 mb-4">How Each Challenge Appears</h5>
                
                <div className="grid gap-6">
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <BarChart3 className="w-5 h-5 text-blue-400" />
                      <span className="font-medium text-blue-300">Computing Power</span>
                    </div>
                    <p className="text-sm text-slate-400 pl-7">
                      Processing millions of posts every second requires massive computing power. The platform needs to check every image, video, and comment in real-time.
                    </p>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Database className="w-5 h-5 text-purple-400" />
                      <span className="font-medium text-purple-300">Data Quality</span>
                    </div>
                    <p className="text-sm text-slate-400 pl-7">
                      The AI learns from user posts and interactions. Poor quality data could lead to biased recommendations or missed harmful content.
                    </p>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Shield className="w-5 h-5 text-red-400" />
                      <span className="font-medium text-red-300">Privacy</span>
                    </div>
                    <p className="text-sm text-slate-400 pl-7">
                      The platform must protect users' private messages and personal information while still monitoring for harmful content.
                    </p>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Brain className="w-5 h-5 text-yellow-400" />
                      <span className="font-medium text-yellow-300">Ethics</span>
                    </div>
                    <p className="text-sm text-slate-400 pl-7">
                      Deciding what content to remove or promote raises questions about free speech, cultural differences, and fairness.
                    </p>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Leaf className="w-5 h-5 text-green-400" />
                      <span className="font-medium text-green-300">Environmental Impact</span>
                    </div>
                    <p className="text-sm text-slate-400 pl-7">
                      Running AI systems 24/7 to monitor millions of posts uses enormous amounts of energy.
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-indigo-300 mb-4">Real-World Impact</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>• Affects billions of users daily</li>
                  <li>• Influences what information people see</li>
                  <li>• Protects users from harmful content</li>
                  <li>• Shapes online conversations</li>
                  <li>• Balances automation with human oversight</li>
                </ul>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <p className="text-slate-300">
                  This example shows how AI challenges aren't just technical problems - they directly affect how we communicate and share information in our daily lives. Getting these systems right is crucial for creating safer, more enjoyable online spaces.
                </p>
              </div>
            </div>
          }
          />
          </div>

          
        




      )
    },


              
  quiz: {
    title: 'Knowledge Check',
    content: (
      <div className="space-y-6">
        <p className="text-gray-300 mb-6">
          Let's test your understanding of Large Language Models with a comprehensive quiz. 
          Choose the best answer for each question.
        </p>
        
        <Quiz questions={[
          {
            questionText: "What are the three essential components required to build Large Language Models?",
            answerOptions: [
              "Software, hardware, and users",
              "Data, computing power, and algorithms",
              "Internet, storage, and programmers",
              "Networks, databases, and applications"
            ],
            correctAnswer: 1,
            explanations: {
              correct: "The three essential components for building LLMs are data (like text from the internet), computing power (including GPUs), and powerful algorithms (like transformers) that enable the model to process language.",
              incorrect: {
                0: "While software and hardware are important, these aren't the three core components discussed in the module.",
                2: "While the internet provides data, this answer misses the crucial components of computing power and algorithms.",
                3: "These are general computing components but not the specific elements required for LLMs."
              }
            }
          },
          {
            questionText: "How do Large Language Models use context to generate responses?",
            answerOptions: [
              "They memorise exact responses from their training",
              "They predict the most likely words based on the given context",
              "They search the internet for answers",
              "They ask other AI systems for help"
            ],
            correctAnswer: 1,
            explanations: {
              correct: "As shown in the examples with 'Barry the dog' and 'The Restaurant', LLMs predict the most likely words to come next based on the context provided.",
              incorrect: {
                0: "LLMs don't simply memorise responses; they learn patterns to generate new text.",
                2: "LLMs don't search the internet; they use their training to generate responses.",
                3: "LLMs work independently based on their training, not by consulting other systems."
              }
            }
          },
          {
            questionText: "Which of these is a key capability of text-only Large Language Models?",
            answerOptions: [
              "Creating original photographs",
              "Making phone calls",
              "Language translation and text analysis",
              "Playing video games"
            ],
            correctAnswer: 2,
            explanations: {
              correct: "As covered in the capabilities section, language translation and text analysis are core functions of text-based LLMs.",
              incorrect: {
                0: "Creating photographs requires multimodal capabilities not available in text-only LLMs.",
                1: "Making phone calls is not a capability of LLMs.",
                3: "Playing video games requires different types of AI systems."
              }
            }
          },
          {
            questionText: "What is a major environmental challenge associated with training Large Language Models?",
            answerOptions: [
              "High carbon emissions from energy usage",
              "Deforestation for paper production",
              "Water pollution from cooling systems",
              "Air pollution from transportation"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As discussed in the environmental impact section, training LLMs requires significant energy consumption, leading to substantial carbon emissions.",
              incorrect: {
                1: "LLMs don't require paper production as they work with digital data.",
                2: "While cooling is important, water pollution isn't a major environmental concern.",
                3: "Transportation isn't directly related to LLM training."
              }
            }
          },
          {
            questionText: "What advancement has enabled more sophisticated language processing in recent years?",
            answerOptions: [
              "The transformer architecture",
              "Faster typing speeds",
              "Better keyboards",
              "Improved screen resolutions"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As mentioned in the module, the transformer architecture has revolutionised how LLMs process and generate text.",
              incorrect: {
                1: "Typing speeds don't affect LLM capabilities.",
                2: "Keyboard technology isn't related to LLM processing.",
                3: "Screen resolution doesn't impact language processing."
              }
            }
          },
          {
            questionText: "Which of these is a key ethical consideration for LLMs?",
            answerOptions: [
              "Bias in model outputs",
              "The colour of the interface",
              "The speed of responses",
              "The cost of hardware"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As covered in the ethical considerations section, bias in model outputs is a crucial ethical concern that needs to be addressed.",
              incorrect: {
                1: "Interface design isn't an ethical consideration.",
                2: "Response speed is a technical, not ethical, consideration.",
                3: "Hardware costs are a practical, not ethical, consideration."
              }
            }
          },
          {
            questionText: "What is the context window in an LLM?",
            answerOptions: [
              "The number of words the model can consider at once",
              "The size of the computer screen",
              "The time between responses",
              "The number of users allowed"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As explained in the capabilities section, the context window refers to the number of words or tokens that the model can process and consider at one time.",
              incorrect: {
                1: "Screen size isn't related to the context window.",
                2: "Response time isn't related to the context window.",
                3: "User numbers aren't related to the context window."
              }
            }
          },
          {
            questionText: "What is a key advantage of multimodal LLMs over text-only models?",
            answerOptions: [
              "They can process multiple types of content like images and text",
              "They are cheaper to run",
              "They work faster",
              "They use less energy"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As discussed in the capabilities section, multimodal LLMs can process multiple types of content, including both images and text, making them more versatile than text-only models.",
              incorrect: {
                1: "Multimodal models typically cost more to run.",
                2: "Processing speed isn't necessarily better in multimodal models.",
                3: "Multimodal models typically use more energy, not less."
              }
            }
          },
          {
            questionText: "What is one solution companies are implementing to reduce the environmental impact of LLMs?",
            answerOptions: [
              "Using renewable energy sources",
              "Using smaller computers",
              "Reducing staff travel",
              "Printing less documentation"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As covered in the environmental impact section, companies are increasingly using renewable energy sources like solar, wind, and hydroelectric power to reduce their carbon footprint.",
              incorrect: {
                1: "LLMs require powerful computers; using smaller ones isn't a solution.",
                2: "Staff travel isn't directly related to LLM environmental impact.",
                3: "Documentation isn't a significant environmental factor for LLMs."
              }
            }
          },
          {
            questionText: "Which of these is a key privacy challenge when using LLMs?",
            answerOptions: [
              "Protecting sensitive data during training and use",
              "Keeping the model's location secret",
              "Preventing users from sharing the interface",
              "Maintaining employee work schedules"
            ],
            correctAnswer: 0,
            explanations: {
              correct: "As discussed in the challenges section, protecting sensitive data during both training and use is a crucial privacy concern for LLMs.",
              incorrect: {
                1: "The model's location isn't a primary privacy concern.",
                2: "Interface sharing isn't a significant privacy issue.",
                3: "Employee schedules aren't a main LLM-specific challenge."
              }
            }
          }
        






        ]} 
        onQuizComplete={() => setIsQuizComplete(true)}
        />
      </div>
    )
  }
}

return (
    <div className="min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-4 right-4 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 bg-slate-900/90 rounded-xl border border-slate-700 hover:bg-slate-800 transition-colors"
        >
          <Menu size={24} />
        </button>
      </div>

      {/* Sidebar Navigation */}
      <div className={`fixed top-0 left-0 h-full bg-slate-900/95 backdrop-blur-md border-r border-slate-800/50 w-64 transform transition-transform duration-200 ease-in-out z-40 overflow-y-auto
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
      >
        <div className="p-6 space-y-6">
          {/* Logo Section */}
          <div className="flex justify-center mb-6">
            <img 
              src="/logo.png" 
              alt="AI DATA CERT Logo" 
              className="h-20 w-auto"
            />
          </div>

          {/* Back Button */}
          <Link 
            to="/llm_user_mastery"
            className="inline-flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors group"
          >
            <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
            <span>Back to Modules</span>
          </Link>

          {/* Navigation Items */}
          <nav className="space-y-1">
            {sections.map(({ id, title, icon: Icon }) => (
              <button
                key={id}
                onClick={() => handleSectionChange(id)}
                className={`w-full text-left px-4 py-3 rounded-xl transition-all flex items-center space-x-3
                  ${activeSection === id 
                    ? 'bg-blue-500/20 text-blue-400 border border-blue-500/50 shadow-lg shadow-blue-500/20' 
                    : 'hover:bg-slate-800/50 text-slate-300'}`}
              >
                <Icon size={18} />
                <span>{title}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="lg:pl-64">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto">
            {/* Content Container */}
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl shadow-xl border border-slate-700/30 p-8 mb-8">
              {/* Header Section */}
              <div className="border-b border-slate-700 pb-6 mb-6">
                <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                  Introduction to Large Language Models
                </h1>
                <h2 className="text-xl text-blue-400">AIDATACERT.COM LTD</h2>
              </div>

              {/* Dynamic Content Section */}
              <div className="space-y-6">
                <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                  {contentSections[activeSection]?.title}
                </h3>
                {contentSections[activeSection]?.content}
              </div>

              </div>

              {/* Next Module Button - Only show when on quiz section and quiz is complete */}
              {activeSection === 'quiz' && isQuizComplete && (
                      <div className="mt-8 flex justify-end">
                        <Link 
                          to="/llm_user_mastery_1_3_accessing_llms"
                          className="inline-flex items-center space-x-2 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-xl transition-colors shadow-lg shadow-blue-500/20 group"
                        >
                          <span>1.2 Complete - Press to start 1.3</span>
                          <ArrowRight size={20} className="group-hover:translate-x-1 transition-transform" />
                        </Link>
                      </div>
                    )}


            </div>
          </div>
        </div>
      </div>

  );
};

export default Oneptwo;


  

            

